import gql from "graphql-tag";

export const BasketCompositionSetsQuery = gql`
query BasketCompositionSets {
  basketCompositionSets {
    id
    deliveryWeek
    status
    basketCompositions {
      id
      deliveryLocation
      basketSize
      totalPortions
    }
  }
}
`;

export const CreateBasketCompositionSetMutation = gql`
mutation CreateBasketCompositionSet($data: BasketCompositionSetCreateInput!) {
  createBasketCompositionSet(
    data: $data
  ) {
    id
  }
}
`;

export const BasketCompositionFieldsFragment = gql`
  fragment BasketCompositionFields on BasketComposition {
    id
    type
    status
    deliveryWeek
    items {
      id
      product {
        id
      }
      packagingOrder

      quantityMini
      portionsMini
      deliveryLocationsMini

      quantitySmall
      portionsSmall
      deliveryLocationsSmall

      quantityLarge
      portionsLarge
      deliveryLocationsLarge
    }
  }
`;

export const BasketCompositionsQuery = gql`
    ${BasketCompositionFieldsFragment}
    query BasketCompositions($where: BasketCompositionWhereInput!, $farmId: ID!) {
        basketCompositions(
            where: {
                deliveryWeek_not: null
                AND:[
                    $where,
                    {farm: {id: $farmId}                    }
                ]
            },
            orderBy: deliveryWeek_DESC) {
            ...BasketCompositionFields
        }
    }`;

export const BasketCompositionQuery = gql`
${BasketCompositionFieldsFragment}
query BasketComposition($id: ID!) {
  basketComposition(where: {id: $id}) {
    ...BasketCompositionFields
  }
}`;

export const BasketCompositionPublishMutation = gql `
${BasketCompositionFieldsFragment}
mutation BasketCompositionPublish(
  $id: ID!
  $deliveries: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput!
){
  updateBasketComposition(
    where: {id: $id}
    data: {
      status: PUBLISHED
      deliveries: $deliveries
    }
  ) {
    ...BasketCompositionFields
  }
}`;
export const BasketCompositionConnectToDeliveryMutation = gql`
${BasketCompositionFieldsFragment}
mutation BasketCompositionConnectToDelivery(
  $id: ID!
  $deliveries: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput!
){
  updateBasketComposition(
    where: {id: $id}
    data: {
      deliveries: $deliveries
    }
  ) {
    ...BasketCompositionFields
  }
}`;


export const BasketCompositionBackToDraftMutation = gql`
${BasketCompositionFieldsFragment}
mutation BasketCompositionBackToDraft(
  $id: ID!
  $deliveries: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput!
){
  updateBasketComposition(
    where: {id: $id}
    data: {
      status: DRAFT
      deliveries: $deliveries
    }
  ) {
    ...BasketCompositionFields
  }
}`;


export const BasketCompositionUpdateMutation = gql`
${BasketCompositionFieldsFragment}
mutation BasketCompositionUpdate(
  $where: BasketCompositionWhereUniqueInput!
  $data: BasketCompositionUpdateInput!
){
  updateBasketComposition(
    where: $where
    data: $data
  ) {
    ...BasketCompositionFields
  }
}`;

export const BasketCompositionDeleteMutation = gql`
${BasketCompositionFieldsFragment}
mutation BasketCompositionDelete(
  $where: BasketCompositionWhereUniqueInput!
){
  deleteBasketComposition(
    where: $where
  ) {
    ...BasketCompositionFields
  }
}`;

export const BasketCompositionUpsertMutation = gql`
${BasketCompositionFieldsFragment}
mutation BasketCompositionUpsert(
  $where: BasketCompositionWhereUniqueInput!
  $create: BasketCompositionCreateInput!
  $update: BasketCompositionUpdateInput!
){
  upsertBasketComposition(
    where: $where
    create: $create
    update: $update
  ) {
    ...BasketCompositionFields
  }
}
`;
