import {DeliveryLocation, Vat} from "../__generated__/types";
import {chain, compact, differenceBy, first, map, sortBy, sum} from "lodash";
import {momentFromIso8601} from "../shared/utils/date.utils";
import {BasketSize, getBasketSize} from "../shared/utils/product.utils";
import {clientOrderTotals} from "./clientOrder.utils";
import {ProductOrderType} from "../shared/utils/productOrder.utils";
import {totalPortions} from "../shared/utils/basketComposition.utils";
import moment from "moment";

interface ProductOrderForBasketCompositionType {
  deliveries: DeliveryType[] | null;
}

export interface DeliveryType {
  plannedDeliveryDate: string;
  deliveryLocation?: DeliveryLocation | null | undefined;
  deliveryDate?: string | undefined | null;
  basketComposition?: { id: string } | null;
  productOrder?: { clientOrder: { client: { id: string } } } | null | undefined;
}

export const findProductOrderByCategory =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, categoryCode: string): T | undefined => {
    return compact(productOrders).find(po => po.product?.category?.code === categoryCode);
  };

export const filterProductOrdersByCategories =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, categoryCodes: string[]): T[] => {
    return compact(productOrders)
      .filter(po => po.product?.category?.code && categoryCodes.includes(po.product.category.code));
  };

export const findProductOrderByProductCode =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, productCode: string): T | undefined => {
    return compact(productOrders).find(po => po.product?.code === productCode);
  };

export const findProductOrderWitProductCodeHavingSize = (size: BasketSize) =>
  <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined => {
    return size && compact(productOrders).find(po => po.product?.code && po.product.code.indexOf(size) > -1);
  };

export const filterProductOrdersWithProductCodeHavingSize = (size: BasketSize) =>
  <T extends ProductOrderType>(productOrders: T[] | null | undefined): T[] => {
    return size ? compact(productOrders).filter(po => po.product?.code && po.product.code.indexOf(size) > -1) : [];
  };

export const productOrderHasProductCodeHavingSize = (size: BasketSize) =>
  <T extends ProductOrderType>(productOrder: T | null | undefined): boolean => {
    return !!size && !!productOrder?.product?.code && productOrder.product.code.indexOf(size) > -1;
  };

export const productOrdersTotalIncl = (
  clientOrders: {
    orderDiscount?: number | null | undefined,
    productOrders: {
      product: { vat?: Vat | null | undefined } | null,
      priceExcl: number
    }[] | null
  }[]) => {
  return chain(clientOrders)
    .map(clientOrder => clientOrderTotals(clientOrder).incl.total)
    .sum()
    .value();
};

export const filterProductOrdersMissingInBasketComposition = <T extends ProductOrderForBasketCompositionType>(
  productOrders: T[] | null | undefined,
  basketComposition: { id: string, deliveryWeek: string } | undefined | null
): T[] => {
  if (!basketComposition) {
    return [];
  }

  let productOrdersWithDeliveryInSameWeek = compact(productOrders)
    .filter(productOrder =>
      compact(productOrder.deliveries)
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(basketComposition.deliveryWeek), 'isoWeek'))
        .filter(delivery => !delivery.basketComposition?.id)
        .length > 0
    );

  let connectedDeliveries = chain(productOrders)
    .map(productOrder => {
      let delivery = compact(productOrder.deliveries)
        .find(delivery => {
          return delivery.basketComposition && delivery.basketComposition?.id === basketComposition.id;
        });
      if (delivery) {
        return ({
          ...productOrder,
          delivery
        });
      }
      return null;
    })
    .compact()
    .value();

  return differenceBy(productOrdersWithDeliveryInSameWeek, connectedDeliveries, 'id');
};

export const filterProductOrdersWithDifferentBasketComposition = <T extends ProductOrderForBasketCompositionType>(
  productOrders: T[] | null | undefined,
  basketComposition: { id: string, deliveryWeek: string } | undefined | null
): T[] => {
  if (!basketComposition) {
    return [];
  }

  return compact(productOrders)
    .filter(productOrder =>
      compact(productOrder.deliveries)
        .filter(delivery =>
          momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(basketComposition.deliveryWeek), 'isoWeek')
        )
        .filter(delivery => delivery.basketComposition?.id && delivery.basketComposition.id !== basketComposition.id)
        .length > 0
    );
};

export const filterDeliveriesByClient = <T extends DeliveryType>(deliveries: T[] | null | undefined, clientId: string): T[] => {
  return compact(deliveries).filter(delivery => delivery.productOrder?.clientOrder.client.id === clientId);
};


export const filterPlannedDeliveries = <T extends DeliveryType>(deliveries: T[] | null | undefined): T[] => {
  return compact(deliveries).filter(delivery => delivery.deliveryDate === null && delivery.plannedDeliveryDate);
};

export const filterDeliveriesByDeliveryLocation = <T extends DeliveryType>(deliveries: T[], deliveryLocation: DeliveryLocation | null | undefined): T[] =>
  deliveries.filter(delivery => delivery.deliveryLocation === deliveryLocation);

export const productOrderHasProductWithCodeSubstring = (productOrder: { product: { code?: string | null | undefined } | null }, codeSubstring: string) =>
  productOrder.product?.code && productOrder.product.code.indexOf(codeSubstring) > -1
;

export const productOrderHasProductWithCategory = (productOrder: {
                                                     product: {
                                                       code?: string | null | undefined
                                                       category?: {
                                                         code?: string | null | undefined
                                                       } | null
                                                     } | null
                                                   },
                                                   categoryCodes: string[]) =>
    productOrder.product?.category?.code && categoryCodes.includes(productOrder.product.category.code);

export const isBasketProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCategory(productOrder, ['pakketten']);
  //productOrderHasProductWithCodeSubstring(productOrder, 'pakketten');

export const isFlexBasketProductOrder = (productOrder: { product: { code?: string | null | undefined, flex?: boolean | null } | null | undefined}) =>
  productOrder?.product?.flex;
  //productOrder.product?.flex || productOrderHasProductWithCodeSubstring(productOrder, '-flex-');

export const isFixedBasketProductOrder = (productOrder: { product: { code?: string | null | undefined, flex?: boolean | null } | null }) =>
  !productOrder?.product?.flex;
  // productOrderHasProductWithCodeSubstring(productOrder, '-vast-');

export const isSelfHarvestProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCategory(productOrder,['zelfoogst']);
// productOrderHasProductWithCodeSubstring(productOrder, 'zelfoogst');

export const isBoxProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCodeSubstring(productOrder, 'pakket-bak');

export const isExtraProductProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCategory(productOrder, ['eieren', 'aardappelen', 'uien']);

export const productOrderIsTrial = (productOrder: { product: { code?: string | null } | null }) => {
  return productOrder.product?.code && productOrder.product.code.indexOf('trial') > -1;
};

export interface DeliveriesInfo<T> {
  all: T[];
  cancelled: T[];
  planned: T[];
  delivered: T[];
  nextPlannedDelivery: T | undefined;
  deliveryForThisWeek: T | undefined;
  count: {
    planned: number;
  };
}


export const deliveryIsCancelled = (delivery: { cancelled: boolean | null }) =>
  delivery.cancelled;
export const deliveryIsDelivered =
  (isSelfHarvest: boolean) =>
    (delivery: {
      plannedDeliveryDate: Date | null;
      deliveryDate: Date | null;
    }) => {
      return isSelfHarvest
        ? moment(delivery.plannedDeliveryDate).isBefore(moment(), 'isoWeek')
        : !!delivery.deliveryDate;
    };
export const deliveryIsPlanned =
  (isSelfHarvest: boolean) =>
    (delivery: {
      plannedDeliveryDate: Date | null;
      deliveryDate: Date | null;
      cancelled: boolean | null;
    }) =>
      moment(delivery.plannedDeliveryDate).isSameOrAfter(moment(), 'isoWeek') &&
      (isSelfHarvest || (!delivery.cancelled && !delivery.deliveryDate));

export const nextPlannedDelivery = <
  T extends {
    plannedDeliveryDate: Date | null;
    deliveryDate: Date | null;
    cancelled: boolean | null;
  },
>(
  deliveries: T[],
  isSelfHarvest: boolean,
): T | undefined => {
  let filteredDeliveries = deliveries.filter(deliveryIsPlanned(isSelfHarvest));
  return first(sortBy(filteredDeliveries, 'plannedDeliveryDate'));
};

export const filterDeliveredDeliveries = <
  T extends {
    plannedDeliveryDate: Date | null;
    deliveryDate: Date | null;
  },
>(
  deliveries: T[],
): T[] => {
  const filteredDeliveries = deliveries.filter(
    (delivery) => delivery.deliveryDate,
  );
  return sortBy(filteredDeliveries, 'plannedDeliveryDate');
};

export const deliveries_deliveredPortions = (
  farmCode: FarmCode,
  product: { code: string | null } | undefined | null,
  deliveries: {
    plannedDeliveryDate: Date;
    deliveryLocation?: string | null | undefined;
    deliveryDate?: Date | undefined | null;
    deliveredProductIds: string[];
    basketComposition:
      | {
      id: string;
      items:
        | {
        portionsMini: number;
        quantityMini: number;
        deliveryLocationsMini: DeliveryLocation[];
        portionsSmall: number;
        quantitySmall: number;
        deliveryLocationsSmall: DeliveryLocation[];
        portionsLarge: number;
        quantityLarge: number;
        deliveryLocationsLarge: DeliveryLocation[];
        product: { id: string } | null;
      }[]
        | undefined
        | null;
    }
      | undefined
      | null;
  }[],
) => {
  return sum(
    deliveries.map((delivery) => {
      let basketSize = getBasketSize(product);
      let deliveredBasketItemRows = compact(
        delivery.basketComposition?.items,
      ).filter(
        (item) =>
          item.product?.id &&
          map(delivery.deliveredProductIds, 'value').includes(item.product.id),
      );
      return totalPortions(
        deliveredBasketItemRows,
        basketSize,
        first(deliveries)?.deliveryLocation as DeliveryLocation,
      );
    }),
  );
};


export const getDeliveriesInfo = <
  T extends {
    cancelled: boolean | null;
    deliveryLocation: string | null;
    deliveryDate: Date | null;
    plannedDeliveryDate: Date | null;
  },
>(
  productOrder:
    | {
    product: {
      category?: { code: string | null } | null;
    } | null;
    deliveries: T[] | null;
  }
    | undefined,
): DeliveriesInfo<T> => {
  if (!productOrder) {
    return {
      all: [],
      cancelled: [],
      planned: [],
      delivered: [],
      nextPlannedDelivery: undefined,
      deliveryForThisWeek: undefined,
      count: { planned: 0 },
    };
  }
  const all = sortBy(productOrder.deliveries, 'plannedDeliveryDate');

  const planned = all.filter(
    deliveryIsPlanned(isSelfHarvestProduct(productOrder.product)),
  );
  const deliveriesInfo = {
    all: all,
    delivered: all.filter((delivery) =>
      deliveryIsDelivered(isSelfHarvestProduct(productOrder.product))(delivery),
    ),
    cancelled: all.filter(deliveryIsCancelled),
    planned,
    nextPlannedDelivery: nextPlannedDelivery(
      all,
      isSelfHarvestProduct(productOrder.product),
    ),
    deliveryForThisWeek: all.find(
      (delivery) =>
        moment(delivery.plannedDeliveryDate).isSame(moment(), 'isoWeek') &&
        !delivery.cancelled,
    ),
    count: { planned: planned.length },
  };

  return deliveriesInfo;
};

export const isSelfHarvestProduct = (
  product: ProductWithCategoryCodeNullable,
) => isProductInCategory(product, 'zelfoogst');

export const isProductInCategory = (
  product: ProductWithCategoryCodeNullable,
  category: string,
) => {
  if (!product) {
    return false;
  }
  return product.category?.code === category;
};

export type ProductWithCategoryCodeNullable =
  | ProductWithCategoryCode
  | null
  | undefined;

type ProductWithCategoryCode = {
  category?: { code: string | null } | null;
};

export type FarmCode = string | undefined | null;
