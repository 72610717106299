import {ProductUnit} from "../../__generated__/types";
import {formatDoubleDigit} from "./currency.utils";

const suffixForUnit = (unit: ProductUnit | null | undefined, amount: number | undefined | null) => {
  let suffix = '';
  if (amount && amount > 1 && unit === ProductUnit.BUNCH || unit === ProductUnit.PIECE) {
    suffix = 's';
  }
  return suffix;
};

export const translatedUnit = (unit: ProductUnit | null | undefined, amount?: number | undefined | null) => {
  let singleUnit = unit === ProductUnit.BUNCH
    ? 'bussel'
    : unit === ProductUnit.KILOGRAMS
      ? 'kg'
      : unit === ProductUnit.PIECE
        ? 'stuk'
        : unit === ProductUnit.GRAMS
          ? 'gram'
          : '';

  return `${singleUnit}${(amount && amount>1) ? suffixForUnit(unit, amount) : ''}`;
};

export const amountWithUnit = (amount: number | undefined, unit: ProductUnit | null) => {
  let unitString = translatedUnit(unit, amount);

  return `${formatDoubleDigit(amount)} ${unitString}`;
};

export const formatAsPoints = (portions: number, blankIfZero?: boolean) => {
  if (blankIfZero && portions === 0) {
    return null;
  }
  return `${formatDoubleDigit(portions)} punt${portions > 1 ? 'en' : ''}`;
};

export const formatAsPointsShort = (portions: number, blankIfZero?: boolean) => {
  if (blankIfZero && portions === 0) {
    return null;
  }
  return `${formatDoubleDigit(portions)} pt.`;
};
