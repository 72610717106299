import {gql} from "@apollo/client";
import { ProductFields_basicFragment } from "shared/queries/product.gql";

export const PointsQuery = gql`
    ${ProductFields_basicFragment}
    query PointsData($afterDeliveryEndDate: DateTime!) {
        clients(
            where: {
                clientOrders_some: {

                    productOrders_some: {
                        product: {
                            code_contains: "pakketten-2023-"
                        }
                      deliveries_some: {
                        plannedDeliveryDate_gt: $afterDeliveryEndDate
                        cancelled:false
                      }
                    }
                }
            }
        ) {
            name
            email
            phone
            firstName
            clientOrders(
                where: {

                    productOrders_some: {
                        product: {
                            code_contains: "pakketten-2023-"
                        }
                      deliveries_some: {
                        plannedDeliveryDate_gt: $afterDeliveryEndDate
                        cancelled:false
                      }
                    }
                }
            ) {
                id
                orderDate
                delivery
                deliveryDate
                deliveryEndDate
                completionStatus
                client { email }
                productOrders(
                    where: {
                        product: {
                            code_contains: "pakketten-2023-"
                        }
                    }
                ) {
                    id
                    product {...ProductFields_basic name}
                    portions
                    deliveries(
                        orderBy: plannedDeliveryDate_ASC
                        where: {
                          cancelled: false
                        }
                    ) {
                        id
                        deliveryDate
                        plannedDeliveryDate
                        cancelled
                        portions
                        deliveredProductIds
                        undeliverableProductIds
                        deliveryLocation
                        basketComposition {
                            id
                            type
                            status
                            deliveryWeek
                            items(where: {OR: [{portionsMini_gt: 0},{portionsSmall_gt: 0},{portionsLarge_gt: 0}]}) {
                                id
                                packagingOrder
                                portionsMini
                                portionsSmall
                                portionsLarge
                                quantityMini
                                deliveryLocationsMini
                                quantitySmall
                                deliveryLocationsSmall
                                quantityLarge
                                deliveryLocationsLarge
                                product {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
