import React, {useState} from 'react';
import {compact, take} from "lodash";
import {momentFromIso8601} from "../../../shared/utils/date.utils";
import BasketCompositionViewer from "./components/BasketCompositionViewer/BasketCompositionViewer";
import {portionAvgForBaskets} from "../../../shared/utils/basketComposition.utils";
import classNames from "classnames";
import {TODAY} from "../../../shared/config";
import {Button} from "primereact/button";
import {useQuery} from "@apollo/client";
import {
    BasketCompositions,
    BasketCompositionsVariables,
    BasketCompositionType, GetProducts,
    GetProductsVariables
} from "../../../__generated__/types";
import {BasketCompositionsQuery} from "../../../shared/queries/basketComposition.gql";
import {GetProductsQuery} from "../../../shared/queries/product.gql";
import {useUserObject} from "../../../shared/context/UserContext";

interface BasketCompositionsListProps {
    flexClientGroupCode: string;
    basketCompositionsSelector: Omit<BasketCompositionsVariables, 'farmId'>;
}

const DEFAULT_VISIBLE = 10;
const BasketCompositionsList = ({flexClientGroupCode, basketCompositionsSelector}: BasketCompositionsListProps) => {
    const {activeFarm} = useUserObject();

    const [nrOfVisibleBasketCompositions, setNrOfVisibleBasketCompositions] = useState<number>(DEFAULT_VISIBLE);
    const [expandedIds, setExpandedIds] = useState<string[]>([]);
    // const [expandedIds, setExpandedIds] = useState<string[]>([]);

    let variables: BasketCompositionsVariables = {
        ...basketCompositionsSelector,
        farmId: activeFarm?.id || '',
        where: {
            ...basketCompositionsSelector.where,
        }
    };
    const {data} = useQuery<BasketCompositions>(BasketCompositionsQuery, {
        variables: variables
    });

    let basketCompositions = compact(data?.basketCompositions);

    const getProductsVariables: GetProductsVariables = {
        where: {},
        farmId: activeFarm?.id || '',
        clientGroupCode: flexClientGroupCode,
    };

    const {data: productsData} = useQuery<GetProducts>(GetProductsQuery, {
        variables: getProductsVariables
    });

    const products = compact(productsData?.products);
    return <div className={"flex flex-wrap gap-1"}>
        {take(basketCompositions, nrOfVisibleBasketCompositions)
            .map(basketComposition => {
                let comparableBasketCompositions = basketCompositions
                    .filter(comparableBasketComposition => {
                        return momentFromIso8601(comparableBasketComposition.deliveryWeek).isBefore(momentFromIso8601(basketComposition.deliveryWeek).subtract(1, 'year').add(2, 'w').startOf('isoWeek'))
                    });
                return <div key={basketComposition.id} className={"col-12"}>
                    {<BasketCompositionViewer
                        products={products}
                        globalAvg={{
                            medium: portionAvgForBaskets(comparableBasketCompositions, [2022,2023,2024], "medium", true),
                            mini: portionAvgForBaskets(comparableBasketCompositions, [2022,2023,2024], "mini", true),
                            large: portionAvgForBaskets(comparableBasketCompositions, [2022,2023,2024], "large", true)
                        }}
                        expanded={expandedIds.includes(basketComposition.id)}
                        onExpand={() => {
                            setExpandedIds(value => {
                                if (value.indexOf(basketComposition.id) != -1) {
                                    value.splice(value.indexOf(basketComposition.id), 1)
                                } else {
                                    value.push(basketComposition.id);
                                }
                                return [...value];
                            });
                        }}
                        basketComposition={basketComposition}
                        className={classNames({
                            'border-2 border-teal-700 bg-teal-100': momentFromIso8601(basketComposition.deliveryWeek).isSame(TODAY, "isoWeek")
                        })
                        }
                        flexClientGroupCode={flexClientGroupCode}/>}
                </div>;
            })}
        {nrOfVisibleBasketCompositions < basketCompositions.length && <Button
            link
            icon="pi pi-arrow-down"
            label="Toon vorige 10"
            onClick={() => setNrOfVisibleBasketCompositions(value => value + 10)}
        />}

    </div>;
};

export default BasketCompositionsList;
