import {Button} from 'primereact/button';
import React, {useState} from 'react';
import {
  BasketCompositionBackToDraft,
  BasketCompositionPublish,
  BasketCompositionPublishVariables,
  BasketCompositions_basketCompositions,
  BasketCompositionStatus,
  ProductOrdersForBasketComposition
} from "../../../../../__generated__/types";
import {useMutation, useQuery} from "@apollo/client";
import {
  BasketCompositionBackToDraftMutation,
  BasketCompositionPublishMutation
} from "../../../../../shared/queries/basketComposition.gql";
import {chain, compact, first, sumBy} from "lodash";
import {asIso8601, momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {ProductOrdersForBasketCompositionQuery} from "../../../../../shared/queries/productOrder.gql";
import {
  notificationsFor
} from "../../../../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";
import {totalPortions} from "../../../../../shared/utils/basketComposition.utils";
import {getBasketSize} from "../../../../../shared/utils/product.utils";

import {nextDelivery} from "../../../../../shared/utils/clientOrder.utils";

interface BasketCompositionPublishControl {
  basketComposition: BasketCompositions_basketCompositions;
  hideActions?: boolean;
}

const BasketCompositionPublishControl = (props: BasketCompositionPublishControl) => {
  let deliveryWeek = momentFromIso8601(props.basketComposition.deliveryWeek);
  let basketCompositionId = props.basketComposition.id;
  let plannedDeliveryStartDate = asIso8601(deliveryWeek.clone().startOf('isoWeek'));
  let plannedDeliveryEndDate = asIso8601(deliveryWeek.clone().endOf('isoWeek'));

  const {data} = useQuery<ProductOrdersForBasketComposition>(ProductOrdersForBasketCompositionQuery, {
    variables: {
      basketCompositionId,
      plannedDeliveryStartDate,
      plannedDeliveryEndDate
    }
  });

  let productOrders = compact(data?.productOrders);

  const [basketCompositionPublish] = useMutation<BasketCompositionPublish>(BasketCompositionPublishMutation, {refetchQueries: "active"});
  const [basketCompositionBackToDraft] = useMutation<BasketCompositionBackToDraft>(BasketCompositionBackToDraftMutation, {refetchQueries: "active"});
  const [updating, setUpdating] = useState<boolean>(false);

  let basketCompositionDeliveryWeek = momentFromIso8601(props.basketComposition?.deliveryWeek);

  let productOrderDeliveryDisconnects = chain(productOrders)
    .map(productOrder => {
      return compact(productOrder.deliveries)
        .find(delivery => delivery.basketComposition && delivery.basketComposition.id === props.basketComposition?.id);
    })
    .compact()
    .map(({id}) => ({id}))
    .value();

  return <div>
    <Button
      disabled={updating}
      loading={updating}
      className={'p-button-link'}
      label={props.basketComposition?.status === BasketCompositionStatus.DRAFT
        ? 'Publiceer dit pakket'
        : props.basketComposition?.status === BasketCompositionStatus.PUBLISHED
          ? 'Pakket terugtrekken'
          : ''
      }
      onClick={() => {
        if (props.basketComposition?.id) {
          setUpdating(true);
          if (props.basketComposition.status === BasketCompositionStatus.PUBLISHED) {
            const variables: BasketCompositionPublishVariables = {
              id: props.basketComposition.id,
              deliveries: {
                disconnect: productOrderDeliveryDisconnects
              },
            };

            basketCompositionBackToDraft({variables})
              .then(() => setUpdating(false));
          } else {
            let productOrdersWithoutErrorNotifications = productOrders.filter((productOrder) => {
              let next = nextDelivery(compact(productOrder.deliveries));
              let plannedDeliveryForBasketComposition = first(productOrder.plannedDeliveryForBasketComposition);
              //let additionalPortions = totalPortions(props.basketComposition?.items, getBasketSize(productOrder?.product), next?.deliveryLocation);

              if (plannedDeliveryForBasketComposition) {
                let notifications = notificationsFor({...productOrder, usedPortions: sumBy(productOrder.deliveries, 'portions')});

                return notifications.findIndex(notification => notification.severity === 'error') === -1;
              }
            });
              //.filter(po => po.clientOrder.client.email === 'vpeltwillem@gmail.com');

            let productOrderDeliveryConnects = chain(productOrdersWithoutErrorNotifications)
              .map(productOrder =>
                compact(productOrder.deliveries)
                  .find(delivery => momentFromIso8601(delivery.plannedDeliveryDate)
                    .isSame(basketCompositionDeliveryWeek, 'isoWeek')))
              .compact()
              .map(({id}) => ({id}))
              .value();

            const variables: BasketCompositionPublishVariables = {
              id: props.basketComposition.id,
              deliveries: {
                connect: productOrderDeliveryConnects
              },
            };

            basketCompositionPublish({variables})
              .then(() => setUpdating(false));
          }
        }
      }}
    />
  </div>;
};

export default BasketCompositionPublishControl;
