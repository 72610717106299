import React, {useRef} from 'react';
import PageHeading from "../../../components/PageHeading";
import {StringParam, useQueryParam} from "use-query-params";
import CustomerBasketComposition from "./components/CustomerBasketComposition";
import FlexSubscriptionStatusOverview from './components/FlexSubscriptionStatusOverview';
import {useMutation, useQuery} from "@apollo/client";
import {
  BasketItemCreateManyWithoutCompositionInput,
  BasketItemCreateWithoutCompositionInput,
  BasketItemUpdateManyWithoutCompositionInput,
  BasketItemUpdateWithWhereUniqueWithoutCompositionInput,
  ClientOrder,
  DeliveryUpdate,
  FarmConfigurationsByCode,
} from "../../../__generated__/types";
import {ClientOrderQuery} from "../../../shared/queries/clientOrder.gql";
import {compact, first, sumBy} from "lodash";
import {DeliveryUpdateMutation} from "../../../shared/queries/productOrder.gql";
import {totalPortions, updateBasketCompositionForFlexDelivery} from 'shared/utils/basketComposition.utils';
import {getBasketSize, portionsFieldKeyForSize, quantityFieldKeyForSize} from "../../../shared/utils/product.utils";
import {Toast} from "primereact/toast";
import FlexNotifications from "./components/FlexNotifications";
import {
  basketProductOrderHasInsufficientPoints_warning,
  remainingPortionsForProductOrder
} from "../../../utils/clientOrder.utils";
import {findBasketProductOrder} from "../../../shared/utils/productOrder.utils";
import {nextDelivery} from "../../../shared/utils/clientOrder.utils";
import {FarmConfigurationsByCodeQuery} from "../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../shared/utils/farmConfig.utils";
import ProductsContainer from "./ProductsContainer";
import {useUserObject} from "../../../shared/context/UserContext";

const ClientBasketFlexPage = () => {
    const [updateDelivery] = useMutation<DeliveryUpdate>(DeliveryUpdateMutation, {refetchQueries: "active"});

    // HARDCODED FARM ID
    // let {activeFarm} = useUserObject();
    let {activeFarm} = {activeFarm: {id: 'clbuwjlqc8rvq0775qw2nhw6l'}};
    const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {
      variables: {
        farmId: activeFarm?.id || '',
        code: FarmConfigCodes.clientGroups_flexCode
      }
    });
    let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

    const toast = useRef<Toast>(null);

    const [id] = useQueryParam('id', StringParam);

    const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id}});

    let productOrders = data?.clientOrder?.productOrders;
    let basketProductOrder = findBasketProductOrder(productOrders);
    let deliveries = compact(basketProductOrder?.deliveries);
    const firstUndelivered = nextDelivery(deliveries);

    // let additionalPortions = totalPortions(firstUndelivered?.basketComposition?.items, getBasketSize(basketProductOrder?.product), firstUndelivered?.deliveryLocation);

    let quantityFieldKey = quantityFieldKeyForSize(getBasketSize(basketProductOrder?.product));
    let portionsFieldKey = portionsFieldKeyForSize(getBasketSize(basketProductOrder?.product));
    let sizeKey = getBasketSize(basketProductOrder?.product);


    let onAddProduct = (addValues: boolean) => (productId: string, quantity: number, portions: number): boolean => {
      let basketComposition = firstUndelivered?.basketComposition;
      let deliveryId = firstUndelivered?.id || '';
      let matchingBasketItem = compact(basketComposition?.items).find(item => item.product.id === productId);

      let cannotPerformOperation = false;

      if (quantityFieldKey && portionsFieldKey && basketProductOrder?.product && firstUndelivered?.deliveryLocation) {
        let updateInput: BasketItemUpdateWithWhereUniqueWithoutCompositionInput | null = null;
        let createInput: BasketItemCreateWithoutCompositionInput | null = null;

        let basicInput: {
          quantityMini: number,
          portionsMini: number,
          quantitySmall: number,
          portionsSmall: number,
          quantityLarge: number,
          portionsLarge: number,
        } = {
          quantityMini: 0,
          portionsMini: 0,
          quantitySmall: 0,
          portionsSmall: 0,
          quantityLarge: 0,
          portionsLarge: 0,
        };
        if (matchingBasketItem) {
          let matchingBasketItemPortions = matchingBasketItem[portionsFieldKey];
          let updatedQuantity = addValues ? matchingBasketItem[quantityFieldKey] + quantity : quantity;
          let updatedPortions = addValues ? matchingBasketItemPortions + portions : portions;
          if (basketProductOrder
            && updatedPortions > remainingPortionsForProductOrder(basketProductOrder)
            && portions > matchingBasketItemPortions) {
            cannotPerformOperation = true;
          }
          updateInput = {
            where: {id: matchingBasketItem.id},
            data: {
              ...basicInput,
              [quantityFieldKey]: updatedQuantity,
              [portionsFieldKey]: updatedPortions,
            }
          };
        } else {
          let updatedQuantity = quantity;
          let updatedPortions = portions;
          if (data?.clientOrder && updatedPortions > remainingPortionsForProductOrder(basketProductOrder)) {
            cannotPerformOperation = true;
          }

          createInput = {
            product: {connect: {id: productId}},
            ...basicInput,
            [quantityFieldKey]: updatedQuantity,
            [portionsFieldKey]: updatedPortions,
          };
        }


        if (cannotPerformOperation) {
          toast.current && toast.current.show({
            severity: 'error',
            summary: 'Je hebt onvoldoende punten voor deze actie',
            life: 3000
          });
          return false;
        } else {
          let updateBasketComposition = updateBasketCompositionForFlexDelivery(
            deliveryId,
            basketComposition,
            sizeKey,
            basketProductOrder?.product,
            firstUndelivered?.deliveryLocation,
            updateDelivery,
            (
              existingItems: BasketItemCreateManyWithoutCompositionInput | null | undefined,
            ): BasketItemCreateManyWithoutCompositionInput => {
              return {
                ...existingItems,
                create: compact([...compact(existingItems?.create), createInput]),
              };
            },
            (): BasketItemUpdateManyWithoutCompositionInput => {
              return {
                create: compact([createInput]),
                update: compact([updateInput])
              }
            });
          updateBasketComposition?.then(() => {
//              getProductLatestStockValue({variables: {id: productId}});
              toast.current && toast.current.show({
                severity: 'success',
                summary: 'Toegevoegd aan je pakket',
                life: 3000
              });

            }
          );
          return true;
        }
      }

      return false;
    };

  let totalRemainingPortions = basketProductOrder ?
    remainingPortionsForProductOrder(basketProductOrder)
    - totalPortions(firstUndelivered?.basketComposition?.items, getBasketSize(basketProductOrder.product), firstUndelivered?.deliveryLocation)
    : 0;

  let deliveredPortions = sumBy(basketProductOrder?.deliveries, 'portions');
  return <div>
      <Toast ref={toast}/>

      <PageHeading title={"Stel je pakket samen"} path={[
        {label: 'Mijn pakket', path: "/mijnpakket"},
        {label: 'Stel je pakket samen', path: "/mijnpakket/flex"}
      ]}/>

      {data?.clientOrder && <FlexNotifications
        basketProductOrder={basketProductOrder ? {...basketProductOrder, usedPortions: deliveredPortions} : undefined}
        clientEmail={data.clientOrder.client.email}
        clientOrderId={data.clientOrder.id}
        nextDelivery={firstUndelivered}
        //additionalPortions={additionalPortions}
      />}

      <div className="grid p-2">
        <div className="col-12 md:col-5 lg:col-3 border-right-1 border-300 pr-2 p-0">
          <div>
            {id && <FlexSubscriptionStatusOverview
              clientOrderId={id}
              deliveryDate={firstUndelivered?.plannedDeliveryDate}
            />}
          </div>
          {id && <CustomerBasketComposition
            clientOrderId={id}
            onChange={onAddProduct(false)}
          />}
        </div>
        <div className="col-12 md:col-7 lg:col-9">
          {basketProductOrder && <ProductsContainer
            flexClientGroupCode={flexClientGroupCode}
            onAddProduct={onAddProduct(true)}
            remainingPortions={totalRemainingPortions}
            // disable={basketProductOrderHasInsufficientPoints_warning(basketProductOrder)}
          />}

        </div>
      </div>
    </div>;
  }
;

export default ClientBasketFlexPage;
