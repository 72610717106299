import React from 'react';
import {
  ClientOrderStatus,
  ClientOrderUpdate,
  ClientOrderUpdateVariables,
  ClientWithDeliveriesForPackaging_productOrders,
  ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
  DeliveryLocation,
  DeliveryUpdate,
  DeliveryUpdateVariables,
  GetProducts_products,
  ProductOrderUpdate,
  ProductOrderUpdateVariables
} from "../../../../__generated__/types";
import {compact, first, isEqual, map, orderBy, sum, uniq, without} from "lodash";
import {
  BasketSize,
  deliveryLocationsFieldKeyForSize,
  getBasketSize,
  portionsFieldKeyForSize,
  quantityFieldKeyForSize
} from "../../../../shared/utils/product.utils";
import {isBasketProductOrder} from "../../../../utils/productOrder.utils";
import ProductPackagingView from "./ProductPackagingView";
import {useMutation} from "@apollo/client";
import {DeliveryUpdateMutation, ProductOrderUpdateMutation} from "../../../../shared/queries/productOrder.gql";
import {asIso8601} from "../../../../shared/utils/date.utils";
import moment, {Moment} from "moment";
import {ClientOrderUpdateMutation} from "../../../../shared/queries/clientOrder.gql";
import {TODAY} from "../../../../shared/config";
import {
  basketItemsForDelivery,
  BasketItemType,
  filterBasketItemsForSize,
  totalPortions
} from "../../../../shared/utils/basketComposition.utils";
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";

interface ProductOrderPackagingViewProps {
  products: GetProducts_products[];
  productOrder: ClientWithDeliveriesForPackaging_productOrders;
  deliveryWeek: Moment;
}

export const filterBasketItemsForSizeAndDeliveryLocation = <
  T extends BasketItemType,
>(
  basketItems: T[] | null | undefined,
  basketSize: BasketSize | undefined,
  deliveryLocation: DeliveryLocation | null | undefined,
): T[] => {
  let deliveryLocationFieldKey = deliveryLocationsFieldKeyForSize(basketSize);

  return filterBasketItemsForSize(basketItems, basketSize, deliveryLocation).filter((item) => {
    let deliveryLocationsForBasketItem = deliveryLocationFieldKey
      ? item[deliveryLocationFieldKey]
      : [];
    return (
      compact(deliveryLocationsForBasketItem).length === 0 ||
      (deliveryLocation &&
        map(deliveryLocationsForBasketItem, 'value').includes(deliveryLocation))
    );
  });
};

export const totalPoints = (
  basketItemRows: BasketItemType[] | null | undefined,
  basketSize: BasketSize | undefined,
  deliveryLocation: DeliveryLocation | undefined | null,
) => {
  if (!basketSize) {
    return 0;
  }
  let portionsFieldKey = portionsFieldKeyForSize(basketSize);
  return sum(
    filterBasketItemsForSizeAndDeliveryLocation(
      basketItemRows,
      basketSize,
      deliveryLocation,
    ).map(
      (basketItemRow) => portionsFieldKey && basketItemRow[portionsFieldKey],
    ),
  );
};

const totalPortionsForDelivery = (
  basketComposition: {
    items: {
      portionsMini: number;
      quantityMini: number;
      deliveryLocationsMini: DeliveryLocation[];
      portionsSmall: number;
      quantitySmall: number;
      deliveryLocationsSmall: DeliveryLocation[];
      portionsLarge: number;
      quantityLarge: number;
      deliveryLocationsLarge: DeliveryLocation[];
      product: { id: string } | null;
    }[] | null;
  },
  delivery: {
    deliveredProductIds: string[];
    deliveryLocation: DeliveryLocation | null;
    productOrder: {
      product: { code?: string | null | undefined } | undefined | null;
    };
  },
) => {
  debugger;
  const basketSize = getBasketSize(
    delivery.productOrder.product,
  );
  return totalPoints(
    compact(basketComposition.items).filter(
      (item) =>
        item.product?.id &&
        compact(delivery.deliveredProductIds).includes(
          item.product.id,
        ),
    ),
    basketSize,
    delivery.deliveryLocation as DeliveryLocation,
  );
};

const ProductOrderPackagingView = (props: ProductOrderPackagingViewProps) => {
    const [updateDelivery] = useMutation<DeliveryUpdate>(DeliveryUpdateMutation);
    const [updateProductOrder] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation);
    const [updateClientOrder] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation);

    console.log(props.productOrder);

    const {productOrder} = props;

    let delivery = first(productOrder.nextDelivery);
    let basketCompositionItems = compact(delivery?.basketComposition?.items);
    let basketSize = getBasketSize(productOrder.product);
    let quantityFieldKey = quantityFieldKeyForSize(basketSize);
    let portionsFieldKey = portionsFieldKeyForSize(basketSize);
    let basketProductOrder = isBasketProductOrder(productOrder);

    const onDeliveryDelivered = (
      productOrder: ClientWithDeliveriesForPackaging_productOrders,
      delivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
      product: {
        id: string
      },
      portions: number,
    ) => (isDelivered: boolean) => {
      if (delivery?.id) {
        let updatedDeliveredProductIds;
        let updatedUndeliverableProductIds = delivery.undeliverableProductIds;

        if (isDelivered) {
          updatedDeliveredProductIds = [...delivery.deliveredProductIds, product.id]
          updatedUndeliverableProductIds = without(delivery.undeliverableProductIds, product.id)
        } else {
          updatedDeliveredProductIds = without(delivery.deliveredProductIds, product.id)
        }
        updatedDeliveredProductIds = uniq(updatedDeliveredProductIds);

        let basketItems = basketItemsForDelivery(basketCompositionItems, productOrder.product, delivery.deliveryLocation);

        let productIdsOfBasketItems = map(basketItems, 'product.id');
        updatedDeliveredProductIds = updatedDeliveredProductIds.filter(productId => productIdsOfBasketItems.includes(productId));

        let deliveryDate = null;
        let portionsForDelivery = null;

        let basketItemProductIds = [...uniq(productIdsOfBasketItems)];
        let deliveredAndUndeliverableProductIds = [...updatedDeliveredProductIds, ...updatedUndeliverableProductIds];
        debugger;
        if ((basketProductOrder && isEqual([...deliveredAndUndeliverableProductIds].sort(), [...basketItemProductIds].sort())
          || (!basketProductOrder && isEqual(deliveredAndUndeliverableProductIds, [product.id])))) {
          deliveryDate = asIso8601(moment());
          if (delivery.basketComposition) {
            portionsForDelivery = totalPortionsForDelivery(delivery.basketComposition, {...delivery,
              deliveredProductIds: updatedDeliveredProductIds,
            });
          }
        }

        const variables2: ProductOrderUpdateVariables = {
          where: {id: productOrder.id},
          data: {
            deliveries: {
              update: [{
                where: {id: delivery?.id},
                data: {
                  deliveryDate,
                  portions: deliveryDate ? portionsForDelivery : null,
                  undeliverableProductIds: {
                    set: updatedUndeliverableProductIds,
                  },
                  deliveredProductIds: {
                    set: updatedDeliveredProductIds,
                  }
                },
              }]
            }
          }
        }

        updateProductOrder({variables: variables2, refetchQueries: "active"});

      }
    };

    const onToggleUndeliverable = (
      productOrder: ClientWithDeliveriesForPackaging_productOrders,
      delivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
      product: {
        id: string
      },
      portions: number,
    ) => (isUndeliverable: boolean) => {
      if (delivery?.id) {
        let updatedUndeliverableProductIds;
        let updatedDeliveredProductIds = delivery.deliveredProductIds;

        if (isUndeliverable) {
          updatedUndeliverableProductIds = [...delivery.undeliverableProductIds, product.id]
          updatedDeliveredProductIds = without(delivery.deliveredProductIds, product.id)
        } else {
          updatedUndeliverableProductIds = without(delivery.undeliverableProductIds, product.id)
        }
        updatedUndeliverableProductIds = uniq(updatedUndeliverableProductIds);

        let basketItems = basketItemsForDelivery(basketCompositionItems, productOrder.product, delivery.deliveryLocation);

        let productIdsOfBasketItems = map(basketItems, 'product.id');
        updatedUndeliverableProductIds = updatedUndeliverableProductIds.filter(productId => productIdsOfBasketItems.includes(productId));

        let deliveryDate = null;
        let portionsForDelivery = null;

        let basketItemProductIds = [...uniq(productIdsOfBasketItems)];
        let deliveredAndUndeliverableProductIds = [...updatedDeliveredProductIds, ...updatedUndeliverableProductIds];
        if ((basketProductOrder && isEqual([...deliveredAndUndeliverableProductIds].sort(), [...basketItemProductIds].sort()))
          || (!basketProductOrder && isEqual(deliveredAndUndeliverableProductIds, [product.id]))) {
          deliveryDate = asIso8601(moment());
          if (delivery.basketComposition) {
            portionsForDelivery = totalPortionsForDelivery(delivery.basketComposition, {...delivery,
              deliveredProductIds: updatedDeliveredProductIds,
            });
          }
        }

        const variables2: ProductOrderUpdateVariables = {
          where: {id: productOrder.id},
          data: {
            deliveries: {
              update: [{
                where: {id: delivery?.id},
                data: {
                  deliveryDate,
                  portions: deliveryDate ? portionsForDelivery : null,
                  undeliverableProductIds: {
                    set: updatedUndeliverableProductIds,
                  },
                  deliveredProductIds: {
                    set: updatedDeliveredProductIds,
                  }
                },
              }]
            }
          }
        }

        updateProductOrder({variables: variables2, refetchQueries: "active"});
      }
    };

    const onExtraProductDeliveryDelivered = (
      delivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
      product: {
        id: string
      } | null,
    ) => (isDelivered: boolean) => {
      if (delivery?.id && product) {
        let updatedDeliveredProductIds;
        if (isDelivered) {
          updatedDeliveredProductIds = [...delivery.deliveredProductIds, product.id]
        } else {
          updatedDeliveredProductIds = without(delivery.deliveredProductIds, product.id)
        }
        updatedDeliveredProductIds = uniq(updatedDeliveredProductIds);

        let deliveryDate = null;
        if ((isEqual(updatedDeliveredProductIds, [product.id]))) {
          deliveryDate = asIso8601(moment());
        }
        const variables: DeliveryUpdateVariables = {
          id: delivery?.id,
          data: {
            deliveryDate,
            deliveredProductIds: {
              set: updatedDeliveredProductIds,
            }
          },
        };
        updateDelivery({variables, refetchQueries: "active"});
      }
    };

    const onProductOrderDelivered = (
      productOrder: {
        id: string;
        clientOrder: { status: ClientOrderStatus; productOrders: { id: string; deliveryDate: string | null }[] | null }
      },
    ) => (isDelivered: boolean) => {

      // const variables: ProductOrderUpdateVariables = {
      //   data: {
      //     deliveryDate: isDelivered ? TODAY : null,
      //   },
      //   where: {id: productOrder.id}
      // };
      debugger;
      let clientOrderIsFullyDelivered = false;
      let allProductOrderIds = map(productOrder.clientOrder.productOrders, 'id');
      let deliveredProductOrders = compact(productOrder.clientOrder.productOrders).filter(po => !!po.deliveryDate);
      // if the new po id is delivered and not already delivered than update the status
      if (isDelivered && allProductOrderIds.length === deliveredProductOrders.length + 1 &&
        deliveredProductOrders.findIndex(po => po.id === productOrder.id) < 0) {
        clientOrderIsFullyDelivered = true;
      }

      const variables: ClientOrderUpdateVariables = {
        data: {
          productOrders: {
            update: [{
              where: {id: productOrder.id},
              data: {deliveryDate: isDelivered ? TODAY : null}
            }]
          },
          status: (clientOrderIsFullyDelivered && productOrder.clientOrder.status === ClientOrderStatus.CREATED)
            ? ClientOrderStatus.DELIVERED
            : (!clientOrderIsFullyDelivered && productOrder.clientOrder.status === ClientOrderStatus.DELIVERED)
              ? ClientOrderStatus.CREATED : productOrder.clientOrder.status,
        },
        where: {id: props.productOrder.clientOrder.id}
      };

      updateClientOrder({variables, refetchQueries: "active"});
    };

    let totalDeliveries = compact(productOrder.allDeliveries);
    let nrOfCurrentDelivery = totalDeliveries.findIndex(d => d.id === delivery?.id) + 1;

    return <div className="border-bottom-1 border-200">
      {delivery && !basketProductOrder && productOrder.product && <ProductPackagingView
        clientGroupId={productOrder.clientOrder.client.group?.id}
        onDelivered={onExtraProductDeliveryDelivered(delivery, productOrder.product)}
        isDelivered={compact(delivery?.deliveredProductIds).findIndex(id => id === productOrder.product?.id) > -1}
        isUndeliverable={false}
        product={productOrder.product}
        quantity={productOrder.quantity * (productOrder.product?.amount || 1)}/>}

      {!delivery && !basketProductOrder && <ProductPackagingView
        clientGroupId={productOrder.clientOrder.client.group?.id}
        onDelivered={onProductOrderDelivered(productOrder)}
        onToggleUndeliverable={() => {
          // IMPLEMENT
        }}
        isDelivered={productOrder.deliveryDate !== null}
        isUndeliverable={false}
        productDescription={productOrder.productDescription}
        product={productOrder.product}
        quantity={productOrder.quantity}/>}

      {basketProductOrder && <div>
        <div className="flex justify-content-between">
          <div>{productOrder.product?.name}</div>
          <div>{nrOfCurrentDelivery} van {totalDeliveries.length} pakketten</div>
        </div>
        {orderBy(basketItemsForDelivery(basketCompositionItems, productOrder.product, delivery?.deliveryLocation), 'packagingOrder')
          .map(basketCompositionItem => {
              let product = props.products.find(p => p.id === basketCompositionItem.product.id);
              if (!product) {
                return null;
              }
              let quantity = quantityFieldKey ? basketCompositionItem[quantityFieldKey] : 0;
              let portions = portionsFieldKey ? basketCompositionItem[portionsFieldKey] : 0;
              if (!delivery || !quantity) {
                return null;
              }

              let productIsDelivered = compact(delivery?.deliveredProductIds).findIndex(id => id === product?.id) > -1;
              let productIsUndeliverable = compact(delivery?.undeliverableProductIds).findIndex(id => id === product?.id) > -1;

              return <ProductPackagingView
                clientGroupId={productOrder.clientOrder.client.group?.id}
                onDelivered={onDeliveryDelivered(productOrder, delivery, product, portions)}
                onToggleUndeliverable={onToggleUndeliverable(productOrder, delivery, product, portions)}
                isDelivered={productIsDelivered}
                isUndeliverable={productIsUndeliverable}
                product={product}
                quantity={quantity * (productOrder.product?.amount || 1)}
                portions={portions}
              />;
            }
          )}
        <div>Volledig geleverd pakket:{formatDoubleDigit(totalPortions(basketCompositionItems, basketSize, delivery?.deliveryLocation))} punten</div>
        {delivery?.portions && <div>Geregistreerde punten: {formatDoubleDigit(delivery.portions)} punten</div>}
      </div>}
    </div>;
  }
;

export default ProductOrderPackagingView;
