import React from 'react';
import {BasketItemRow} from "../../BasketItemsTable";
import {useQuery} from "@apollo/client";
import {GetProducts_products, ProductOrdersForBasketComposition} from "../../../../../../../__generated__/types";
import {ProductOrdersForBasketCompositionQuery} from "../../../../../../../shared/queries/productOrder.gql";
import {asIso8601} from "../../../../../../../shared/utils/date.utils";
import {compact, first} from "lodash";
import {Moment} from "moment";
import {amountWithUnit} from "../../../../../../../shared/utils/unit.utils";
import {productOrderHasProductCodeHavingSize} from "../../../../../../../utils/productOrder.utils";
import {
  BasketSize,
  findProductPriceForClientGroupCode,
  productIsAvailableAsAlternativeUnitFor,
  productItemQuantity
} from "../../../../../../../shared/utils/product.utils";

interface TotalColumnProps {
  deliveryWeek: Moment;
  basketCompositionId?: string;
  basketItemRow: BasketItemRow;
  product: GetProducts_products;
  flexClientGroupCode: string;
}

const TotalColumn = (props: TotalColumnProps) => {
  const {basketItemRow, deliveryWeek, basketCompositionId, flexClientGroupCode} = props;

  let plannedDeliveryStartDate = deliveryWeek ? asIso8601(deliveryWeek.clone().startOf('isoWeek')) : null;
  let plannedDeliveryEndDate = deliveryWeek ? asIso8601(deliveryWeek.clone().endOf('isoWeek')) : null;

  const {data: productOrdersData} = useQuery<ProductOrdersForBasketComposition>(ProductOrdersForBasketCompositionQuery, {
    variables: {
      basketCompositionId,
      plannedDeliveryStartDate,
      plannedDeliveryEndDate
    }
  });

  const productOrders = (size: BasketSize) => compact(productOrdersData?.productOrders)
    .filter(productOrderHasProductCodeHavingSize(size))
    .filter(productOrder => {
        let deliveryLocationForProductOrder = first(productOrder.deliveries)?.deliveryLocation;
        let deliveryLocationsForBasketItemRow = size === 'large'
          ? props.basketItemRow.deliveryLocationsLarge
          : size === 'mini'
            ? props.basketItemRow.deliveryLocationsMini
            : props.basketItemRow.deliveryLocationsSmall;
        return deliveryLocationsForBasketItemRow?.length === 0
          || (deliveryLocationForProductOrder && productOrder.deliveries && deliveryLocationsForBasketItemRow.includes(deliveryLocationForProductOrder));
      }
    )
    .filter(productOrder =>
      compact(productOrder.deliveries)
        .findIndex(delivery => !delivery.basketComposition || delivery.basketComposition?.id === basketCompositionId) > -1)
  ;

  let productOrdersLarge = productOrders('large');
  let productOrdersSmall = productOrders('medium');
  let productOrdersMini = productOrders('mini');

  let product = basketItemRow.product;

  let productPrice = findProductPriceForClientGroupCode(product, flexClientGroupCode);

  if (product?.amount) {
    let totalLarge = productItemQuantity(basketItemRow.quantityLarge, product.amount) * productOrdersLarge.length;
    let totalSmall = productItemQuantity(basketItemRow.quantitySmall, product.amount) * productOrdersSmall.length;
    let totalMini = productItemQuantity(basketItemRow.quantityMini, product.amount) * productOrdersMini.length;
    let unit = product.unit;

    let availableAsAlternativeUnit = productIsAvailableAsAlternativeUnitFor(product, productPrice?.clientGroup?.id);
    if (product.avgWeight && availableAsAlternativeUnit) {
      unit = product.alternativeUnit;
      totalLarge = totalLarge / product.avgWeight;
      totalSmall = totalSmall / product.avgWeight;
      totalMini = totalMini / product.avgWeight;
    }

    return <div>
      <div className="text-xs text-800 white-space-nowrap">
        {totalMini > 0 && <span>M: {amountWithUnit(totalMini, unit)}</span>}
        {totalMini > 0 && (totalSmall > 0 ) && ' + '}
        {totalSmall > 0 && <span>K: {amountWithUnit(totalSmall, unit)}</span>}
        {totalSmall > 0 && totalLarge > 0 && ' + '}
        {totalLarge > 0 && <span>G: {amountWithUnit(totalLarge, unit)}</span>}
      </div>
      <div className="font-bold">
        = {amountWithUnit(totalMini + totalSmall + totalLarge, unit)}
        {availableAsAlternativeUnit && product.avgWeight &&
          <span
            className={"font-normal"}> ({amountWithUnit((totalMini + totalSmall + totalLarge) * product.avgWeight, product.unit)})</span>}
      </div>
    </div>;
  }
  return <div/>;
};

export default TotalColumn;
