import {chain, compact, last, sum, sumBy,} from "lodash";
import {DeliveryLocation, Vat} from "../__generated__/types";
import {vat} from "../shared/utils/vat.utils";
import {
  findBasketProductOrder,
  findFixed15BasketProductOrder,
  findFlexBasketProductOrder
} from "../shared/utils/productOrder.utils";
import {momentFromIso8601} from "../shared/utils/date.utils";
import {TODAY} from "../shared/config";
import {getBasketSize} from "../shared/utils/product.utils";
import {totalPortions} from "../shared/utils/basketComposition.utils";
import {filterDeliveredDeliveries, isFlexBasketProductOrder} from "./productOrder.utils";

interface ClientOrderType {
  productOrders: {
    priceExcl?: number,
    product?: {
      category: { code: string | null } | null,
      vat?: Vat | undefined,
    } | null
  }[] | null
}

export const clientOrderTotalExcl = (clientOrder: {
  productOrders: {
    priceExcl: number,
  }[] | null,
  delivery: boolean,
}) => {
  let value = sum(compact(clientOrder.productOrders).map(productOrder => productOrder.priceExcl));
  value = clientOrder.delivery ? value + 7.5 : value;

  return value;
};
export const clientOrderTotalInclWithoutDelivery = (clientOrder: {
  orderDiscount?: number | null,
  productOrders: {
    priceExcl?: number | undefined,
    vat?: Vat | undefined | null,
    product?: {
      vat?: Vat | undefined,
    } | null
  }[] | null | undefined,
}) => chain(clientOrder.productOrders)
  .compact()
  .map((productOrder) => {
    const vatValue = productOrder.product?.vat || productOrder.vat;
    return vatValue && productOrder.priceExcl ? productOrder.priceExcl * (1 + vat(vatValue)) : 0;
  })
  .sum()
  .multiply(clientOrder.orderDiscount ? (1 - clientOrder.orderDiscount) : 1)
  .value();


export const valueInclVat = (value: number, vatCode: Vat | undefined | null) => value + vatForValue(value, vatCode);
export const vatForValue = (value: number | null | undefined, vatCode: Vat | undefined | null) => value ? value * vat(vatCode) : 0;

export const productOrderPriceWithDiscount = (
  inclVat: boolean,
  orderDiscount?: number | null | undefined
) => (productOrder: {
  priceExcl?: number | null | undefined,
  product?: { vat?: Vat | undefined | null } | null,
  vat?: Vat | undefined | null
}) => {
  let productOrderTotal = productOrder.priceExcl;
  if (!productOrderTotal) {
    return 0;
  }

  if (productOrderTotal > 0) {
    productOrderTotal = productOrderTotal * (1 - (orderDiscount || 0));
  }

  return inclVat ? valueInclVat(productOrderTotal, productOrder.product?.vat || productOrder.vat) : productOrderTotal;
};

let productOrderPriceWithoutDiscount = (
  inclVat: boolean
) => (productOrder: { priceExcl?: number | null | undefined, product?: { vat?: Vat | undefined | null } | null },) => {
  let productOrderTotal = productOrder.priceExcl;
  if (!productOrderTotal) {
    return 0;
  }
  return inclVat ? valueInclVat(productOrderTotal, productOrder.product?.vat) : productOrderTotal;
};

export const productOrderDiscount = (
  inclVat: boolean,
  orderDiscount?: number | null | undefined
) => (productOrder: { priceExcl?: number | null | undefined, product?: { vat?: Vat | undefined | null } | null },) => {
  let productOrderTotal = productOrder.priceExcl;
  if (!productOrderTotal) {
    return 0;
  }

  if (productOrderTotal > 0) {
    productOrderTotal = productOrderTotal * (orderDiscount || 0);
  }

  return inclVat ? valueInclVat(productOrderTotal, productOrder.product?.vat) : productOrderTotal;
};


const clientOrderPriceWithDiscount = (inclVat: boolean, productOrders: {}[], orderDiscount: number | null | undefined) => {
  return sum(compact(productOrders).map(productOrderPriceWithDiscount(inclVat, orderDiscount)));
};

const clientOrderPriceWithoutDiscount = (inclVat: boolean, productOrders: {}[]) => {
  return sum(compact(productOrders).map(productOrderPriceWithoutDiscount(inclVat)));
};

const clientOrderDiscount = (inclVat: boolean, productOrders: {}[], orderDiscount: number | null | undefined) => {
  return sum(compact(productOrders).map(productOrderDiscount(inclVat, orderDiscount)));
};

export const clientOrderTotals = (clientOrder: {
  orderDiscount?: number | null | undefined,
  productOrders: {}[] | null
}): {
  incl: { subTotal: number, total: number, orderDiscount: number },
  excl: { subTotal: number, total: number, orderDiscount: number },
} => {
  let productOrders = compact(clientOrder.productOrders);
  return {
    incl: {
      subTotal: clientOrderPriceWithoutDiscount(true, productOrders),
      total: clientOrderPriceWithDiscount(true, productOrders, clientOrder.orderDiscount),
      orderDiscount: clientOrderDiscount(true, productOrders, clientOrder.orderDiscount),
    },
    excl: {
      subTotal: clientOrderPriceWithoutDiscount(false, productOrders),
      total: clientOrderPriceWithDiscount(false, productOrders, clientOrder.orderDiscount),
      orderDiscount: clientOrderDiscount(false, productOrders, clientOrder.orderDiscount),
    }
  };
};

export const filterClientOrdersByCategory =
  <T extends ClientOrderType>(clientOrders: T[] | null | undefined, categoryCodes: string[]): T[] => {
    if (categoryCodes.length === 0) {
      return compact(clientOrders);
    }
    return compact(clientOrders)
      .filter(co => compact(co.productOrders)
        .findIndex(po => po.product?.category?.code && categoryCodes.includes(po.product.category.code)) > -1
      );
  };

export const totalRevenue = <T extends ClientOrderType>(clientOrders: T[], productCategories?: string[]): number =>
  chain(filterClientOrdersByCategory(clientOrders, compact(productCategories)))
    .map(clientOrder => clientOrderTotalInclWithoutDelivery(clientOrder))
    .sum()
    .value();

export const filterFlexBasketClientOrders = <T extends ClientOrderType>(clientOrders: T[] | null | undefined): T[] => compact(clientOrders)
  .filter(clientOrder => findFlexBasketProductOrder(clientOrder.productOrders) !== undefined);

export const filterFixed15BasketClientOrders = <T extends ClientOrderType>(clientOrders: T[] | null | undefined): T[] => compact(clientOrders)
  .filter(clientOrder => findFixed15BasketProductOrder(clientOrder.productOrders) !== undefined);

export const filterBasketClientOrders = <T extends ClientOrderType>(clientOrders: T[] | null | undefined): T[] => compact(clientOrders)
  .filter(clientOrder => findBasketProductOrder(clientOrder.productOrders) !== undefined);


export const remainingPortionsForProductOrder = (productOrder: {
  portions: number | null,
  deliveries: {portions: number|null}[]|null;
}) => {
  let deliveredPortions = sumBy(productOrder.deliveries, 'portions');
  return (productOrder.portions !== null && deliveredPortions !== null)
    ? productOrder.portions - deliveredPortions
    : 0;
};

export type PlannedDeliveryForBasketComposition = {
  id?: string;
  deliveryDate: any | null;
  plannedDeliveryDate: any;
  deliveryLocation: DeliveryLocation | null;
  cancelled: boolean;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: {
    id: string;
    items: {
      product: { id: string };
      quantityMini: number;
      portionsMini: number;
      deliveryLocationsMini: DeliveryLocation[];
      quantitySmall: number;
      portionsSmall: number;
      deliveryLocationsSmall: DeliveryLocation[];
      quantityLarge: number;
      portionsLarge: number;
      deliveryLocationsLarge: DeliveryLocation[];
    }[] | null;
  } | null;
}

export const basketProductOrderHasInsufficientPoints_warning = (productOrder: {
  id: string;
  portions: number | null;
  deliveries: {
    id: string;
    portions: number | null;
    deliveryDate: any | null;
    plannedDeliveryDate: any;
    deliveryLocation: DeliveryLocation | null;
    cancelled: boolean;
    basketComposition: {
      id: string;
    } | null;
  }[] | null;
  product: { id: string, code: string | null, flex?: boolean | null } | null;
}) => {
  if (!isFlexBasketProductOrder(productOrder)) {
    return false;
  }


  let allDeliveries = compact(productOrder?.deliveries);
  const deliveredDeliveries = filterDeliveredDeliveries(allDeliveries);

  let nrOfDeliveries = allDeliveries.length;
  if (nrOfDeliveries === deliveredDeliveries.length) {
    return false;
  }

  let basketSize = getBasketSize(productOrder.product);
  let totalRemainingPortions = remainingPortionsForProductOrder(productOrder);

  if (basketSize === "large" && totalRemainingPortions >= 20 && totalRemainingPortions <= 40) {
    return true;
  }
  if (basketSize === "medium" && totalRemainingPortions >= 13 && totalRemainingPortions <= 26) {
    return true;
  }
  return false;
  // return (additionalPortions >= totalRemainingPortions) && totalRemainingPortions / additionalPortions >= 0.5;
};

function remainingDeliveryCountIsLessThan(productOrder: {
  id: string;
  deliveries: { id: string; deliveryDate: any; plannedDeliveryDate: any; cancelled: boolean }[] | null
}, value: number) {
  let allDeliveries = compact(productOrder.deliveries).filter(d => !d.cancelled);
  const deliveredDeliveries = filterDeliveredDeliveries(allDeliveries);

  let remainingDeliveries = allDeliveries.length - deliveredDeliveries.length;
  return remainingDeliveries < value;
}

function remainingDeliveryCountIsMoreThan(productOrder: {
  id: string;
  deliveries: { id: string; deliveryDate: any; plannedDeliveryDate: any; cancelled: boolean }[] | null
}, value: number) {
  let allDeliveries = compact(productOrder.deliveries).filter(d => !d.cancelled);
  const deliveredDeliveries = filterDeliveredDeliveries(allDeliveries);

  let remainingDeliveries = allDeliveries.length - deliveredDeliveries.length;
  return remainingDeliveries > value;
}

export const basketProductOrderIsRunningOutOfDeliveries_info = (productOrder: {
  id: string;
  deliveries: {
    id: string;
    deliveryDate: any | null;
    plannedDeliveryDate: any;
    cancelled: boolean;
  }[] | null;
}) => {
  return remainingDeliveryCountIsLessThan(productOrder, 3);
};

export const basketProductOrderIsRunningOutOfDeliveries_warning = (productOrder: {
  id: string;
  deliveries: {
    id: string;
    deliveryDate: any | null;
    plannedDeliveryDate: any;
    cancelled: boolean;
  }[] | null;
}) => {
  return remainingDeliveryCountIsLessThan(productOrder, 2);
};

export const basketProductOrderHasInsufficientPoints_error = (productOrder: {
  id: string;
  portions: number | null;
  deliveries: {
    id: string;
    deliveryDate: any | null;
    portions: number | null;
    plannedDeliveryDate: any;
    deliveryLocation: DeliveryLocation | null;
    cancelled: boolean;
    basketComposition: {
      id: string;
    } | null;
  }[] | null;
  product: { id: string, code: string | null, flex?: boolean | null } | null;
}) => {
  if (!isFlexBasketProductOrder(productOrder)) {
    return false;
  }
  let allDeliveries = compact(productOrder.deliveries);
  const deliveredDeliveries = filterDeliveredDeliveries(allDeliveries);

  let nrOfDeliveries = allDeliveries.length;
  if (nrOfDeliveries === deliveredDeliveries.length) {
    return false;
  }
  let basketSize = getBasketSize(productOrder.product);
  let totalRemainingPortions = remainingPortionsForProductOrder(productOrder);

  if (basketSize === "large" && totalRemainingPortions < 20) {
    return true;
  }
  if (basketSize === "medium" && totalRemainingPortions < 13) {
    return true;
  }
  return false;

  //return (additionalPortions >= totalRemainingPortions) && (totalRemainingPortions / additionalPortions < 0.5);
};

export const basketProductOrderIsOutOfBalance = (
  productOrder: {
    id: string;
    portions: number | null;
    deliveries: {
      id: string;
      portions: number | null;
      deliveryDate: any | null;
      plannedDeliveryDate: any;
      deliveryLocation: DeliveryLocation | null;
      cancelled: boolean;
      basketComposition: {
        id: string;
      } | null;
    }[] | null;
    product: {
      id: string;
      name: string;
      code: string | null;
      flex?: boolean | null;
    } | null;
  }
) => {
  if (!isFlexBasketProductOrder(productOrder)) {
    return false;
  }

  if (remainingDeliveryCountIsMoreThan(productOrder, 4)) {
    return false;
  }

  let allDeliveries = compact(productOrder.deliveries);
  const deliveredDeliveries = filterDeliveredDeliveries(allDeliveries);

  let nrOfDeliveries = allDeliveries.length;
  if (nrOfDeliveries === deliveredDeliveries.length) {
    return false;
  }

  let totalRemainingPortions = remainingPortionsForProductOrder(productOrder);

  if (productOrder?.portions) {
    const theoreticalAvgPerDelivery = productOrder?.portions / nrOfDeliveries;
    let nrOfDeliveredDeliveriesInclCurrent = deliveredDeliveries.length;
    const remainingAvgPerDelivery = totalRemainingPortions / (nrOfDeliveries - nrOfDeliveredDeliveriesInclCurrent);

    return (remainingAvgPerDelivery / theoreticalAvgPerDelivery) < 0.75;
  }

  return false;
};

export const lastUndeliveredDelivery = <T extends {
  plannedDeliveryDate?: string | null | undefined,
  deliveryDate?: string | null | undefined
}>(deliveries: T[]): T | undefined => {
  return last(deliveries.filter(delivery => delivery.plannedDeliveryDate && delivery.deliveryDate === null && momentFromIso8601(delivery.plannedDeliveryDate).isAfter(TODAY)))
}

export const delivery_expectedTotalPortions = (
  product: { code: string | null } | undefined | null,
  delivery: {
    plannedDeliveryDate: string;
    deliveryLocation?: DeliveryLocation | null | undefined;
    deliveryDate?: string | undefined | null;
    deliveredProductIds: string[],
    basketComposition: {
      id: string,
      items: {
        portionsMini: number,
        quantityMini: number,
        deliveryLocationsMini: DeliveryLocation[],
        portionsSmall: number,
        quantitySmall: number,
        deliveryLocationsSmall: DeliveryLocation[],
        portionsLarge: number,
        quantityLarge: number,
        deliveryLocationsLarge: DeliveryLocation[],
        product: { id: string }
      }[] | null
    } | null,
  }) => {
  return totalPortions(compact(delivery.basketComposition?.items), getBasketSize(product), delivery.deliveryLocation);
}
