import gql from "graphql-tag";
import { ProductFields_basicFragment } from "shared/queries/product.gql";

export const DashboardItemYearOverviewQuery = gql`
${ProductFields_basicFragment}
query BasketYearOverview($farmId: ID!) {
  productOrders(
    where: {
    product: {
      category: {
        code: "pakketten"
      }
    }
    clientOrder: {
      client: {
        farm: {id: $farmId}
      }
    }
  }
) {
    id
    product {
      ...ProductFields_basic
    }
    deliveries(where: {cancelled: false}) {
      id
      plannedDeliveryDate
      deliveryDate
      portions
      productOrder {
    		clientOrder { id client {name firstName email} }
      }
    }
  }
}`;
