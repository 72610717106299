import React from 'react';
import {BasketItemRow} from "../../BasketItemsTable";
import DeliveryLocationSelection from "../../DeliveryLocationSelection";
import {DeliveryLocation} from "../../../../../../../__generated__/types";
import {capitalize} from "lodash";
import {
  findProductPriceForClientGroupCode,
  portionsBasedOnProductOrderAmount
} from "../../../../../../../shared/utils/product.utils";
import ProductQuantityInput from 'shared/components/ProductQuantityInput';

interface QuantityCellProps {
  size: 'large' | 'small' | 'mini';
  flexClientGroupCode: string;
  basketItemRow: BasketItemRow;
  disabled: boolean | undefined;
  onChange: (basketItem: {
    id: string | undefined,
    product: { id: string },

    quantityMini: number,
    portionsMini: number,
    deliveryLocationsMini: DeliveryLocation[];

    quantitySmall: number,
    portionsSmall: number,
    deliveryLocationsSmall: DeliveryLocation[];

    quantityLarge: number,
    portionsLarge: number,
    deliveryLocationsLarge: DeliveryLocation[];

    delete?: boolean
  }) => void;
}

const QuantityCell = (props: QuantityCellProps) => {
  const {basketItemRow, flexClientGroupCode} = props;
  let quantityFieldKey = 'quantity' + capitalize(props.size) as 'quantityMini' | 'quantitySmall' | 'quantityLarge' ;
  let portionsFieldKey = 'portions' + capitalize(props.size) as 'portionsMini' | 'portionsSmall' | 'portionsLarge' ;
  let deliveryLocationsFieldKey = 'deliveryLocations' + capitalize(props.size) as 'deliveryLocationsMini' | 'deliveryLocationsSmall' | 'deliveryLocationsLarge' ;

  let quantityFieldValue = basketItemRow[quantityFieldKey];
  let deliveryLocationsField = basketItemRow[deliveryLocationsFieldKey];

  let productPrice = findProductPriceForClientGroupCode(basketItemRow.product, flexClientGroupCode);
  return basketItemRow.product ? <div>
      <ProductQuantityInput
        clientGroupId={productPrice?.clientGroup?.id}
        disabled={props.disabled}
        product={basketItemRow.product}
        value={quantityFieldValue}
        hideSaveButton
        allowZero
        allowHalfBunch
        onChange={(updatedQuantity: number | null) => {
          if (basketItemRow.product) {
            props.onChange({...basketItemRow,
              product: basketItemRow.product,
              [quantityFieldKey]: updatedQuantity,
              [portionsFieldKey]: portionsBasedOnProductOrderAmount(basketItemRow.product, productPrice, updatedQuantity || 0),
            });
          }
        }}
      />
      <DeliveryLocationSelection
        expandable
        label="Selecteer leverpunten:"
        value={deliveryLocationsField}
        onChange={(deliveryLocationsLarge: DeliveryLocation[]) => {
          if (basketItemRow.product) {
            props.onChange({...basketItemRow, product: basketItemRow.product, [deliveryLocationsFieldKey]:deliveryLocationsLarge });
          }
        }}
      />
    </div>
    : null;
};

export default QuantityCell;
