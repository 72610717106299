import React from 'react';
import DashboardItemClientNextBasket from "./DashboardItemClientNextBasket";
import {ClientOrders_clientOrders, ClientOrdersVariables} from "../../../../__generated__/types";
import {asIso8601} from "../../../../shared/utils/date.utils";
import {TODAY} from "../../../../shared/config";
import {useQuery} from "@apollo/client";
import {ClientOrdersQuery} from "../../../../shared/queries/clientOrder.gql";
import {compact} from "lodash";
import {filterClientOrdersByCategory} from "../../../../utils/clientOrder.utils";
import {findBasketProductOrder} from "../../../../shared/utils/productOrder.utils";
import {filterDeliveredDeliveries} from "../../../../utils/productOrder.utils";

interface ClientBasketDashboardProps {
  clientId: string;
}

const ClientBasketDashboard = (props: ClientBasketDashboardProps) => {
  const variables: ClientOrdersVariables = {
    where: {
      client: {id: props.clientId},
      deliveryEndDate_gte: asIso8601(TODAY),
    }
  };
  const {data} = useQuery(ClientOrdersQuery, {variables});
debugger;
  let activeClientOrders: ClientOrders_clientOrders[] = compact(data?.clientOrders);
  let basketClientOrders = filterClientOrdersByCategory(activeClientOrders, ['pakketten']);
  basketClientOrders = basketClientOrders
    .filter(clientOrder => {
      let basketProductOrder = findBasketProductOrder(clientOrder.productOrders);
      let deliveries = compact(basketProductOrder?.deliveries).filter(d => !d.cancelled);
      let deliveredDeliveries = filterDeliveredDeliveries(deliveries);
      return deliveredDeliveries.length !== deliveries?.length;
      }
    )

  if (basketClientOrders.length > 0) {
    return <div className="m-1 md:m-2">
      <div className="md:m-1 grid mt-3">
        {basketClientOrders.length > 0 && basketClientOrders.map((clientOrder, idx) => {
          return <div key={`${idx}_basket`} className={`col-12 ${idx > 0 && 'mt-8 opacity-40'}`}>
            <DashboardItemClientNextBasket clientOrderId={clientOrder.id}/>
          </div>;
        })}
      </div>
    </div>;
  } else {
    return null;
  }
};

export default ClientBasketDashboard;
