import React from 'react';
import {asDdMmm, momentFromIso8601} from 'shared/utils/date.utils';
import {
  BasketCompositions_basketCompositions,
  DeliveryLocation,
  GetProducts_products
} from "../../../../../__generated__/types";
import {compact, maxBy, minBy, uniqBy} from "lodash";
import {Button} from "primereact/button";
import BasketCompositionStatusControl from './BasketCompositionStatusControl';
import classNames from "classnames";
import {Message} from "primereact/message";
import {deliveryLocations} from "../../../../../shared/utils/deliveryLocation.utils";
import {Props} from 'shared/types/types';
import {
  portionDiffersFromCurrentlyCalculatedPortions,
  totalPortions
} from "../../../../../shared/utils/basketComposition.utils";
import {formatDoubleDigit} from "../../../../../shared/utils/currency.utils";
import {BasketSize} from "../../../../../shared/utils/product.utils";
import BasketCompositionViewerDetail from './BasketCompositionViewerDetail';

interface BasketCompositionViewerProps {
    flexClientGroupCode: string;
    basketComposition: BasketCompositions_basketCompositions;
    products: GetProducts_products[];
    expanded?: boolean;
    onExpand?: () => void;
    globalAvg?: { [key in "mini" |"large" | "medium"]: number };
}

export interface BasketItem {
    id: string;
    product: {
        id: string
    },

    quantityMini: number,
    portionsMini: number,
    deliveryLocationsMini: DeliveryLocation[];

    quantitySmall: number,
    portionsSmall: number,
    deliveryLocationsSmall: DeliveryLocation[];

    quantityLarge: number,
    portionsLarge: number,
    deliveryLocationsLarge: DeliveryLocation[];

    packagingOrder: number;
}

const BasketCompositionViewer = (props: Props<BasketCompositionViewerProps>) => {
    const {basketComposition} = props;

    let deliveryWeek = momentFromIso8601(basketComposition.deliveryWeek);

    let weekTitle =
        <span>Week {deliveryWeek.format('W')}: {asDdMmm(deliveryWeek.clone().isoWeekday(5))}</span>;
    let basketItemRows = compact(basketComposition.items);

    let totalPortionsValue = (basketSize: BasketSize) => {
        let totalPortionsByDeliveryLocation = deliveryLocations.map(deliveryLocation => {
            return {
                deliveryLocation: deliveryLocation.value,
                totalPortions: totalPortions(basketItemRows, basketSize, deliveryLocation.value)
            }
        });

        let portionsForDeliveryLocation = (deliveryLocation: {
            deliveryLocation: DeliveryLocation,
            totalPortions: number
        } | undefined) => {
            let portionAvgForBasketSize = props.globalAvg && basketSize && props.globalAvg[basketSize];
            return <span
                key={`pointsFor_${deliveryLocation?.deliveryLocation}`}
                className={classNames("p-1", {
                    "text-red-600": deliveryLocation && portionAvgForBasketSize && portionAvgForBasketSize > deliveryLocation.totalPortions,
                    "text-red-800 font-bold": deliveryLocation && portionAvgForBasketSize && portionAvgForBasketSize > deliveryLocation.totalPortions && portionAvgForBasketSize > deliveryLocation.totalPortions * 1.25,
                    "text-green-600": deliveryLocation && portionAvgForBasketSize && portionAvgForBasketSize < deliveryLocation.totalPortions,
                    "text-green-800 font-bold": deliveryLocation && portionAvgForBasketSize && portionAvgForBasketSize < deliveryLocation.totalPortions && portionAvgForBasketSize < deliveryLocation.totalPortions * 1.25
                })}>
        {deliveryLocation && formatDoubleDigit(deliveryLocation.totalPortions)}
      </span>;
        };

        let totalValues;
        if (uniqBy(totalPortionsByDeliveryLocation, 'totalPortions').length === 1) {
            totalValues = [portionsForDeliveryLocation(totalPortionsByDeliveryLocation[0])];
        } else {
            totalValues = [
                portionsForDeliveryLocation(minBy(totalPortionsByDeliveryLocation, 'totalPortions')),
                " - ",
                portionsForDeliveryLocation(maxBy(totalPortionsByDeliveryLocation, 'totalPortions'))
            ];
        }

        return <div
            className="flex-grow-1 flex flex-column align-items-center justify-content-center w-3rem  min-w-min">
            <div
                className="font-normal text-lg white-space-nowrap">
                {totalValues.map(totalValue => totalValue)}
            </div>
            <div className="text-700 text-xs">{basketSize === "large" ? "Groot" : basketSize === "mini" ? "Mini": "Klein"}</div>
        </div>;
    };

    return <div className={classNames(`shadow-2 p-3 border-1 border-300 border-round ${props.className}`)}>
        <div className="flex justify-content-between align-items-center">
            <Button
                className={"p-button-icon p-button-text p-0text-600 text-xl"}
                icon={`pi pi-chevron${props.expanded ? '-down' : '-right'}`}
                onClick={props.onExpand}
            />
            <div className="ml-3 flex justify-content-center align-items-center">
                {props.flexClientGroupCode &&
                    basketItemRows
                        .map(basketItemRow => {
                                const product = props.products.find(p => p.id === basketItemRow.product.id);
                                return portionDiffersFromCurrentlyCalculatedPortions(props.flexClientGroupCode, basketItemRow, "medium", product) ||
                                    portionDiffersFromCurrentlyCalculatedPortions(props.flexClientGroupCode, basketItemRow, "large", product) ||
                                    portionDiffersFromCurrentlyCalculatedPortions(props.flexClientGroupCode, basketItemRow, "mini", product);
                            }
                        ).includes(true) && <Message
                        text="Fout in porties"
                        severity="warn"
                        className="py-1 px-2 justify-content-start"
                    />
                }
            </div>
            <div className="font-normal text-xl ml-3 flex-grow-1">{weekTitle}</div>
            <div>
                <BasketCompositionStatusControl
                    expanded={props.expanded}
                    basketComposition={basketComposition}
                />
            </div>
            <div className="flex align-items-center ml-3 w-8rem">
                <div className="pr-2">{totalPortionsValue("mini")}</div>
                <div className="pr-2">{totalPortionsValue("medium")}</div>
                {totalPortionsValue("large")}
            </div>
        </div>
        {
            props.expanded &&
            <>
                <BasketCompositionViewerDetail
                    products={props.products}
                    basketComposition={props.basketComposition}
                />
            </>
        }
    </div>;
};

export default BasketCompositionViewer;
