import {AutoComplete, AutoCompleteCompleteEvent} from 'primereact/autocomplete';
import React, {useState} from 'react';
import {useQuery} from "@apollo/client";
import {GetProducts_simpleQuery, GetProductsQuery} from "../../../../../shared/queries/product.gql";
import {
  DeliveryLocation, FarmConfigurationsByCode,
  GetProducts,
  GetProducts_products, GetProducts_Simple, GetProducts_Simple_products, GetProducts_SimpleVariables,
  GetProductsVariables,
  ProductStatus
} from "../../../../../__generated__/types";
import {compact, first, sortBy} from "lodash";
import {translatedUnit} from "../../../../../shared/utils/unit.utils";
import {findProductPriceForClientGroupCode} from "../../../../../shared/utils/product.utils";
import {useUserObject} from "../../../../../shared/context/UserContext";
import {FarmConfigurationsByCodeQuery} from "../../../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../../../shared/utils/farmConfig.utils";

interface ProductAutoCompleteProps {
  value?: GetProducts_Simple_products;
  onChange: (basketItem: {
    id: string | undefined,
    product: { id: string },

    quantityMini: number,
    portionsMini: number,
    deliveryLocationsMini: DeliveryLocation[];

    quantitySmall: number,
    portionsSmall: number,
    deliveryLocationsSmall: DeliveryLocation[];

    quantityLarge: number,
    portionsLarge: number,
    deliveryLocationsLarge: DeliveryLocation[];

  }) => void;
}

const itemTemplate = (flexClientGroupCode: string) => (product: GetProducts_products) => {
  const {amount, name, unit} = product;
  const flexProductPrice = findProductPriceForClientGroupCode(product, flexClientGroupCode);
  return (
    <div className="flex align-items-center">
      <div className="w-2rem max-w-2rem">
        {product.image && <img alt={product.name} src={product.image} className="w-2rem max-w-2rem"/>}
        {!product.image && <i className="pi pi-image w-2rem max-w-2rem"/>}
      </div>
      <div className="pl-2">
        <div className="font-bold">{name}</div>
        <div className={"text-600 text-xs"}>{amount} {translatedUnit(unit)} = {flexProductPrice?.value} punten</div>
      </div>
    </div>
  );
}

const ProductAutoComplete = (props: ProductAutoCompleteProps) => {
  const  {activeFarm} = useUserObject();
  const variables: GetProducts_SimpleVariables = {
    where: {
      status: ProductStatus.ACTIVE
    },
    farmId: activeFarm?.id || '',
  }
  const {data} = useQuery<GetProducts_Simple>(GetProducts_simpleQuery, {variables});
  const [selectedProduct, setSelectedProduct] = useState<GetProducts_Simple_products>();
  const [filteredProducts, setFilteredProducts] = useState<GetProducts_Simple_products[]>([]);

  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  const products: GetProducts_Simple_products[] = sortBy(compact(data?.products), 'name');
  const searchProduct = (event: AutoCompleteCompleteEvent) => {
    setTimeout(() => {
      let _filteredProducts;
      if (!event.query.trim().length) {
        _filteredProducts = products;
      } else {
        _filteredProducts = products.filter((product) => {
          return product.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
        });
      }

      setFilteredProducts(_filteredProducts);
    }, 250);
  }
  return <AutoComplete
    value={selectedProduct}
    suggestions={filteredProducts}
    completeMethod={searchProduct}
    placeholder="Selecteer een product"
    field="name"
    dropdown
    forceSelection
    itemTemplate={itemTemplate(flexClientGroupCode)}
    onSelect={e => {
      if (e.value) {
        props.onChange({
          id: undefined,
          product: {
            id: e.value.id
          },
          quantityMini: 0,
          portionsMini: 0,
          deliveryLocationsMini: [],
          quantitySmall: 0,
          portionsSmall: 0,
          deliveryLocationsSmall: [],
          quantityLarge: 0,
          portionsLarge: 0,
          deliveryLocationsLarge: [],
        });
        setSelectedProduct(undefined);
      }
    }}
    onChange={(e) => {
      setSelectedProduct(e.value);
    }}
    aria-label="Producten"
  />;
};

export default ProductAutoComplete;
