import React, {useEffect, useState} from 'react';
import {StringParam, useQueryParam} from "use-query-params";
import MoveDeliveryView from "./components/MoveDeliveryView";
import {first} from "lodash";
import {useClientObject} from "../../ClientContext";
import {filterFlexBasketClientOrders} from "../../utils/clientOrder.utils";
import {findBasketProductOrder} from "../../shared/utils/productOrder.utils";

const ClientBasketDeliveriesPage = () => {
  const [clientOrderIdQueryParam] = useQueryParam('clientOrderId', StringParam);
  const [deliveryId] = useQueryParam('deliveryId', StringParam);
  const [clientOrderId, setClientOrderId] = useState<string | undefined>(clientOrderIdQueryParam);

  const {client} = useClientObject();

  useEffect(() => {
    debugger;
    let flexBasketClientOrders = filterFlexBasketClientOrders(client?.clientOrders);

    if (flexBasketClientOrders.length === 1) {
      setClientOrderId(first(flexBasketClientOrders)?.id);
    }
  }, [client]);

  if (clientOrderId && deliveryId) {
    return <div>
      <div>
        {deliveryId && clientOrderIdQueryParam &&
          <MoveDeliveryView deliveryId={deliveryId} clientOrderId={clientOrderIdQueryParam}/>}
      </div>
    </div>;
  } else {

    if (clientOrderId) {
      return <MoveDeliveryView clientOrderId={clientOrderId}/>;
    } else {
      return <div>
        <div className="text-xl font-medium">Kies je pakketabonnement:</div>
        {filterFlexBasketClientOrders(client?.clientOrders)
          .map(clientOrder => <div
            key={clientOrder.id}
            onClick={() => {
          setClientOrderId(clientOrder.id)
        }} className="">
          {findBasketProductOrder(clientOrder.productOrders)?.product?.name}
        </div>)}
      </div>;
    }
  }
};

export default ClientBasketDeliveriesPage;
