import React, {useEffect, useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import ProductList from "../../../shared/ProductList/ProductList";
import {GetProducts, GetProducts_products, GetProductsVariables, ProductStatus} from "../../../__generated__/types";
import {useUserObject} from "../../../shared/context/UserContext";
import {useQuery} from "@apollo/client";
import {GetProductsQuery} from "../../../shared/queries/product.gql";
import {compact, isEmpty} from "lodash";
import {Toast} from "primereact/toast";

interface ProductsContainerProps {
  flexClientGroupCode: string;
  disable?: boolean;
  remainingPortions: number;
  onAddProduct: (productId: string, quantity: number, portions: number) => void,
}

const ProductsContainer = (props: ProductsContainerProps) => {
  const {activeFarm} = useUserObject();


  const variables: GetProductsVariables = {
    clientGroupCode: props.flexClientGroupCode,
    where: {
      status: ProductStatus.ACTIVE,
      productAvailabilities_some: {
        endDate: null,
        clientGroup: {code: props.flexClientGroupCode}
      }
    },
    farmId: activeFarm?.id || ''
  };
  const {data: productsData} = useQuery<GetProducts>(GetProductsQuery, {
    variables
  });

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredProducts, setFilteredProducts] = useState<GetProducts_products[]>([]);

  const toast = useRef<Toast>(null);

  const products = compact(productsData?.products);

  useEffect(() => {
    console.log(searchValue);
    if (!isEmpty(searchValue)) {
      setFilteredProducts(products
        .filter(product => product.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      );
    } else {
      setFilteredProducts(products);
    }

  }, [searchValue, productsData]);
  return <div>
    <Toast ref={toast}/>

    <div className="text-xl font-medium mb-2">Het flex aanbod voor deze week</div>
    <div className={'p-fluid mb-4'}>
          <span className="p-input-icon-left" style={{width: '100%'}}>
                        <i className="pi pi-search"/>
                        <InputText
                          value={searchValue}
                          onChange={(e: any) => {
                            setSearchValue(e.target.value);
                          }}
                          placeholder="Zoek in het aanbod"
                        />
          </span>
    </div>
    {productsData?.clientGroup &&
      <ProductList
        remainingPortions={props.remainingPortions}
        //disable={props.disable}
        showIn={"points"}
        clientGroup={productsData.clientGroup}
        products={filteredProducts}
        onAdd={props.onAddProduct}
      />}
  </div>;
};

export default ProductsContainer;
