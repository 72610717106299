import React from 'react';
import {
  BasketCompositions_basketCompositions,
  ProductOrdersForBasketComposition
} from "../../../../../__generated__/types";
import {
  filterBasketProductOrders,
  filterFixedBasketProductOrders,
  filterFlexBasketProductOrders
} from "../../../../../shared/utils/productOrder.utils";
import CounterValues from './ProductOrdersCounter/CounterValues';
import {useQuery} from "@apollo/client";
import {ProductOrdersForBasketCompositionQuery} from "../../../../../shared/queries/productOrder.gql";
import {asIso8601} from "../../../../../shared/utils/date.utils";
import {Moment} from "moment";
import {compact, size} from "lodash";

interface ProductOrdersCounterProps {
  basketComposition: BasketCompositions_basketCompositions | null | undefined;
  deliveryWeek: Moment;
}

const ProductOrdersCounter = (props: ProductOrdersCounterProps) => {
  let rows = [
    {label: "Flex", productOrdersFilterFn: filterFlexBasketProductOrders,},
    {label: "Vast", productOrdersFilterFn: filterFixedBasketProductOrders,}
  ];
  let {basketComposition, deliveryWeek} = props;

  let basketCompositionId = props.basketComposition?.id;
  let plannedDeliveryStartDate = asIso8601(deliveryWeek.clone().startOf('isoWeek'));
  let plannedDeliveryEndDate = asIso8601(deliveryWeek.clone().endOf('isoWeek'));
  const {data: productOrdersData} = useQuery<ProductOrdersForBasketComposition>(ProductOrdersForBasketCompositionQuery, {
    variables: {
      basketCompositionId,
      plannedDeliveryStartDate,
      plannedDeliveryEndDate
    }
  });

  let productOrders = compact(productOrdersData?.productOrders);
  return <div className={"grid mr-2 p-2 text-sm min-w-15rem"}>
    <div className="col-3 py-0 border-right-1 border-bottom-1 border-400"></div>
    <div className="col-2 py-0 text-center font-bold border-bottom-1 border-400">Mini</div>
    <div className="col-2 py-0 text-center font-bold border-bottom-1 border-400">Klein</div>
    <div className="col-2 py-0 text-center font-bold border-bottom-1 border-400">Groot</div>
    <div className="col-3 py-0 border-bottom-1 border-400"></div>

    {rows.map(row => <>
      <div className="col-3 py-0 text-center font-bold border-right-1 border-400">{row.label}</div>
      {(['mini', "medium", "large"] as ('mini' | 'medium' | 'large')[]).map((size) => {
        return <CounterValues
          key={`values_${size}`}
          status={basketComposition?.status}
          basketComposition={basketComposition}
          productOrders={row.productOrdersFilterFn(productOrders, [size])}
        />;
      })}
      <div className="col-2 py-0 text-center">{row.productOrdersFilterFn(productOrders).length}</div>
    </>)}

    <div className="col-3 py-0 border-right-1 border-400"></div>
    <div className="col-2 py-0 text-center">{filterBasketProductOrders(productOrders, ["mini"]).length}</div>
    <div className="col-2 py-0 text-center">{filterBasketProductOrders(productOrders, ["medium"]).length}</div>
    <div className="col-2 py-0 text-center">{filterBasketProductOrders(productOrders, ["large"]).length}</div>
    <div className="col-2 py-0 text-center">{filterBasketProductOrders(productOrders).length}</div>

  </div>;
};

export default ProductOrdersCounter;
