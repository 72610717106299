import {
  BasketComposition_basketComposition_items,
  BasketCompositionCreateWithoutDeliveriesInput, BasketCompositions_basketCompositions,
  BasketCompositionStatus,
  BasketCompositionType,
  BasketItemCreateManyWithoutCompositionInput,
  BasketItemUpdateManyWithoutCompositionInput,
  DeliveryLocation,
  DeliveryUpdateVariables
} from "../../__generated__/types";
import {chain, compact, filter, first, mean, sum} from "lodash";
import {
  BasketSize,
  deliveryLocationsFieldKeyForSize,
  findProductPriceForClientGroupCode,
  getBasketSize,
  portionsBasedOnProductOrderAmount,
  portionsFieldKeyForSize,
  ProductPriceType,
  quantityFieldKeyForSize
} from "./product.utils";
import {momentFromIso8601} from "./date.utils";
import moment from "moment";


export const updateBasketCompositionForFlexDelivery = (
  deliveryId: string,
  basketComposition: {
    deliveryWeek: string,
    type: BasketCompositionType,
    items: BasketComposition_basketComposition_items[] | null
  } | null | undefined,
  basketSize: BasketSize,
  basketProduct: { code: string | null },
  deliveryLocation: DeliveryLocation,
  updateDeliveryMethod: (opts: { variables: DeliveryUpdateVariables }) => any,
  itemsUpdateForFixedComposition: (
    existingItems: BasketItemCreateManyWithoutCompositionInput | null | undefined,
  ) => BasketItemCreateManyWithoutCompositionInput,
  itemsUpdateForCustomerBasedComposition: () => BasketItemUpdateManyWithoutCompositionInput,
) => {

  let variables: DeliveryUpdateVariables | null = null;
  if (basketComposition?.type === BasketCompositionType.CUSTOMER_BASED) {
    variables = {
      id: deliveryId,
      data: {
        basketComposition: {
          update: {
            items: itemsUpdateForCustomerBasedComposition(),
          }
        }
      }
    };
  } else if (basketComposition?.type === BasketCompositionType.FIXED) {
    let createBasketCompositionInput = createCustomerBasedBasketComposition(basketComposition, basketSize, basketProduct, deliveryLocation);
    variables = {
      id: deliveryId,
      data: {
        basketComposition: {
          create: {
            ...createBasketCompositionInput,
            items: itemsUpdateForFixedComposition(createBasketCompositionInput.items),
          }
        }
      }
    };
  }
  if (variables) {
    return updateDeliveryMethod({variables});
  }
};

const createCustomerBasedBasketComposition = (
  fromFixedBasketComposition: {
    deliveryWeek: string,
    items: BasketComposition_basketComposition_items[] | null
  },
  basketSize: BasketSize,
  basketProduct: { code: string | null },
  deliveryLocation: DeliveryLocation,
): BasketCompositionCreateWithoutDeliveriesInput => {
  let quantityFieldKey = quantityFieldKeyForSize(basketSize);
  let portionsFieldKey = portionsFieldKeyForSize(basketSize);

  return {
    deliveryWeek: fromFixedBasketComposition.deliveryWeek,
    status: BasketCompositionStatus.PUBLISHED,
    type: BasketCompositionType.CUSTOMER_BASED,
    farm: {connect: {code: "groentegeweld"}},
    items: {
      create: compact(compact(basketItemsForDelivery(fromFixedBasketComposition.items, basketProduct, deliveryLocation))
        // CHECK THIS, it creates items for a medium basket while only choosen for a large basket
        .filter(item => quantityFieldKey && item[quantityFieldKey] > 0)
        .map(item => {
          if (quantityFieldKey && portionsFieldKey) {
            return {
              quantityMini: 0,
              portionsMini: 0,
              quantitySmall: 0,
              portionsSmall: 0,
              quantityLarge: 0,
              portionsLarge: 0,
              [quantityFieldKey]: item[quantityFieldKey],
              [portionsFieldKey]: item[portionsFieldKey],
              packagingOrder: item.packagingOrder,
              product: {
                connect: {id: item.product.id},
              }
            };
          }
        })),
    },
  }
};

export const basketItemsForDelivery = <T extends BasketItemType>(
  basketItems: T[] | null | undefined,
  product: { code: string | null } | null | undefined,
  deliveryLocation: DeliveryLocation | null | undefined): T[] => {
  return filterBasketItemsForSize(basketItems, getBasketSize(product), deliveryLocation);
};

export const portionDiffersFromCurrentlyCalculatedPortions = (
  flexClientGroupCode: string,
  basketItemRow: {
    portionsMini: number;
    portionsSmall: number;
    portionsLarge: number;
    quantitySmall: number;
    quantityMini: number;
    quantityLarge: number
  },
  basketSize: BasketSize,
  product: {
    productPrices: ProductPriceType[] | null;
    availableAsAlternativeUnitFor: string[] | null;
    avgWeight?: number | null | undefined;
    amount?: number | undefined | null
  } | undefined
  ) => {
  let portionsFieldKey = portionsFieldKeyForSize(basketSize);
  let quantityFieldKey = quantityFieldKeyForSize(basketSize);
  if (portionsFieldKey && quantityFieldKey) {
    let currentPortions = 0;
    if (product) {
      let productPrice = findProductPriceForClientGroupCode(product, flexClientGroupCode);
      currentPortions = portionsBasedOnProductOrderAmount(product, productPrice, basketItemRow[quantityFieldKey]);
    }
    return currentPortions !== basketItemRow[portionsFieldKey];
  }
};

export type BasketItemType = {
  // id: string;
  // product: BasketComposition_basketComposition_items_product;
  // packagingOrder: number;
  portionsMini: number;
  quantityMini: number;
  deliveryLocationsMini: DeliveryLocation[];
  portionsSmall: number;
  quantitySmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  portionsLarge: number;
  quantityLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export const filterBasketItemsForSize = <T extends BasketItemType>(
  basketItems: T[] | null | undefined,
  basketSize: BasketSize,
  deliveryLocation: DeliveryLocation | null | undefined): T[] => {
  let quantityFieldKey = quantityFieldKeyForSize(basketSize);
  let deliveryLocationFieldKey = deliveryLocationsFieldKeyForSize(basketSize);

  return compact(basketItems)
    .filter(item => quantityFieldKey && item[quantityFieldKey] > 0)
    .filter(item => {
      let deliveryLocationsForBasketItem = deliveryLocationFieldKey ? item[deliveryLocationFieldKey] as DeliveryLocation[] : [];
      return compact(deliveryLocationsForBasketItem).length === 0
        || (deliveryLocation && deliveryLocationsForBasketItem.includes(deliveryLocation));
    });
};


export const totalPortions = (basketItemRows: BasketItemType[] | null | undefined,
                              basketSize: BasketSize,
                              deliveryLocation: DeliveryLocation | undefined | null,
) => {
  let portionsFieldKey = portionsFieldKeyForSize(basketSize);
  return sum(filterBasketItemsForSize(basketItemRows, basketSize, deliveryLocation)
    .map(basketItemRow => portionsFieldKey && basketItemRow[portionsFieldKey]));
}


export const avgPointsSuggestion = (basketCompositions: BasketCompositions_basketCompositions[], basketSize: BasketSize, deliveryLocation: DeliveryLocation | null | undefined) => {
  let portionsForComparableBasketCompositions: number[] = [];
  if (compact(basketCompositions)) {
    portionsForComparableBasketCompositions = basketCompositions
      .map(basketComposition => totalPortions(basketComposition.items, basketSize, deliveryLocation));
  }
  return mean(portionsForComparableBasketCompositions);
};


export const portionAvgForBaskets = (basketCompositions: BasketCompositions_basketCompositions[],
                                     year: number[], basketSize: BasketSize, onlyIsoweeksUptoThisWeek: boolean) => chain(basketCompositions)
  .filter(basketComposition => year.includes(momentFromIso8601(basketComposition.deliveryWeek).year()))
  .filter(basketComposition => onlyIsoweeksUptoThisWeek ? momentFromIso8601(basketComposition.deliveryWeek).isoWeek() <= moment().isoWeek() : true)
  .filter(basketComposition => basketComposition.status === BasketCompositionStatus.PUBLISHED)
  .map(basketComposition => totalPortions(compact(basketComposition.items), basketSize, DeliveryLocation.WEELDE))
  .mean()
  .value();
