import gql from "graphql-tag";
import { ProductFields_basicFragment } from "shared/queries/product.gql";

export const ActiveSubscriptionsQuery = gql`
${ProductFields_basicFragment}
${ProductFields_basicFragment}
query ActiveSubscriptions( $farmId: ID!) {
  productsInClientOrders: products(
    where: {
      productOrders_some: {
        clientOrder: {
          client: {
            farm: {
              id: $farmId
            }
          }
          productOrders_some: {
            product: {
          type: SUBSCRIPTION
        }
          }
        }
      }
    }
  ) {
    ...ProductFields_basic
    name
    vat
    amount
    unit
  }
  clientOrders(
    where: {
      productOrders_some: {
        clientOrder: {
          client: {
            farm: {
              id: $farmId
            }
          }
        }
        product: {
          type: SUBSCRIPTION
        }
      }
    }
  ) {
    id
  	createdAt
    status
    completionStatus
    orderDiscount
    orderDate
    delivery
    deliveryDate
    deliveryEndDate
    orderDiscount
    productOrders {
      id
      quantity
      remark
      priceExcl
      ambassadorDiscount
      frequency
      portions
      productDescription
      unit
      vat
      deliveries(orderBy: plannedDeliveryDate_ASC) {
        id
        plannedDeliveryDate
        deliveryDate
        cancelled
        portions
        deliveryLocation
        deliveredProductIds
        undeliverableProductIds
        basketComposition {
          id
          type
        }
      }
      product {
        ...ProductFields_basic
      }
    }
    client {
      id
      farm { id code }
      name
      firstName
      email
      secondaryEmail
      type
      auth0userId
      remarks
      membership {
        id
        season
        name
        firstName
      }
    }
    invoice {
      id
      filename
      file
      payments {
        id
        amount
        dueDate
        paid
      }
    }
  }
}
`;
