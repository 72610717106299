import {compact, first, isEmpty} from "lodash";
import {PriceUnit, ProductPromoType, ProductType, Vat} from "../../__generated__/types";
import {momentFromIso8601} from "./date.utils";
import moment from "moment";
import {vat} from "./vat.utils";
import {roundHalf} from "./number.utils";
import {POINTS_PRICE_INCL, TODAY} from "shared/config";

export const units: { [key: string]: string } = {
  PIECE: " stuk",
  KILOGRAMS: "kg",
  GRAMS: "g",
  BUNCH: " bussel",
};

export type ProductPromo = {
  id: string;
  startDate: any;
  endDate: any | null;
  type: ProductPromoType;
  value: number;
  secondValue: number | null;
};


export type ProductPriceType = {
  id: string;
  clientGroup: { id: string; default?: boolean | null, code?: string | undefined | null } | null;
  value: number;
  base: boolean | null;
  endDate: any | null;
  note: string | null;
  unit: PriceUnit | null;
};

interface ProductWithProductPricesType {
  productPrices: ProductPriceType[] | null
}

export const filterActiveProductPrices = <T extends ProductWithProductPricesType>(product: T | null | undefined) => {
  return compact(product?.productPrices).filter(productPrice => productPrice.endDate === null);
};

export const findProductPriceForClientGroup = <T extends ProductWithProductPricesType>(product: T | null | undefined,
                                                                                       clientGroupId?: string | null): ProductPriceType | undefined | null => {
  if (!clientGroupId) {
    return undefined;
  }
  return compact(product?.productPrices)
    .find(productPrice => productPrice.endDate === null
      && (productPrice.clientGroup
        ? productPrice.clientGroup.id === clientGroupId
        : !clientGroupId ? productPrice.base : null));
};

export const findProductPriceForClientGroupCode = <T extends ProductWithProductPricesType>(product: T | null | undefined,
                                                                                           clientGroupCode?: string | null): ProductPriceType | undefined | null => {
  if (!clientGroupCode) {
    return undefined;
  }
  return compact(product?.productPrices)
    .find(productPrice => productPrice.endDate === null
      && (productPrice.clientGroup
        ? productPrice.clientGroup.code === clientGroupCode
        : !clientGroupCode ? productPrice.base : null));
};

export const findProductPriceForDefaultClientGroup = <T extends ProductWithProductPricesType>(product: T | null | undefined): ProductPriceType | undefined | null => {
  return compact(product?.productPrices)
    .find(productPrice => productPrice.endDate === null && productPrice.clientGroup?.default);
};

export const hasProductPriceForClientGroup = <T extends ProductWithProductPricesType>(product: T | null | undefined,
                                                                                      clientGroupId?: string | null): boolean => {
  if (!clientGroupId) {
    return false;
  }
  return (compact(product?.productPrices).find(productPrice => productPrice.endDate === null && productPrice.clientGroup?.id === clientGroupId)?.value || 0) > 0;
};

export const getProductPriceIncl = (product: {
  productPrices: { value: number }[] | null,
  vat: Vat,
} | undefined | null) => {
  return product ? (first(product.productPrices)?.value || NaN) * (1 + (vat(product.vat))) : NaN
};

export const activePromo = (product: {
                              productPromos?: ProductPromo[] | null,
                            },
                            ofType?: ProductPromoType[]): ProductPromo | undefined => {
  return compact(product.productPromos)
    .find(promo =>
      (!ofType || isEmpty(ofType) || ofType?.includes(promo.type))
      && (!promo.endDate || momentFromIso8601(promo.endDate).isSameOrAfter(moment(), 'd'))
    );
};

export const pricePerPortion = (p: { portionWillem: number | null, vat: Vat, productPrices: { value: number, clientGroup?: { code: string | null } | null }[] | null }) => {
  const field = 'portionWillem';
  const value: number = p[field] as number;
  if (value) {
    const price = compact(p.productPrices).find(productPrice => productPrice.clientGroup?.code === "standaard")?.value;

    const portion = value;
    return ({
      ...p,
      portion: portion,
      portionVsPrice: (price || 0) * (1 + vat(p.vat)) / portion
    });
  }
};

export const availabilityFor = (product: {
                                  productAvailabilities?: {
                                    id: string,
                                    clientGroup: { id: string } | null,
                                    endDate: string,
                                    startDate: string,
                                  }[] | null;
                                } | null | undefined,
                                clientGroupId?: string | undefined | null) =>
  compact(product?.productAvailabilities)
    .filter(productAvailability => productAvailability.clientGroup?.id === clientGroupId)
    .find(productAvailability =>
      productAvailability.endDate === null || momentFromIso8601(productAvailability.startDate).isSameOrAfter(TODAY, 'day')
    );


export const product_hasAvailability = (product: {
                                  productAvailabilities?: {
                                    id: string,
                                    clientGroup: { id: string; code: string | null } | null,
                                    endDate: string,
                                    startDate: string,
                                  }[] | null;
                                } | null | undefined,
                                        clientGroupCode?: string | null) =>
  compact(product?.productAvailabilities)
    .findIndex(productAvailability =>
      (productAvailability.endDate === null || momentFromIso8601(productAvailability.startDate).isSameOrAfter(TODAY, 'day')) && (!clientGroupCode ||
        clientGroupCode === productAvailability.clientGroup?.code)
    )> -1;

export const isIndexable = (product?: { type?: ProductType | null, productPrices?: { note?: string | null }[] | null }) => product?.type === ProductType.SIMPLE
  && compact(product.productPrices).findIndex(productPrice => productPrice.note === 'index-2023') === -1;

export const productItemQuantity = (itemQuantity: number, productAmount?: number | null | undefined) =>
  productAmount
    ? (itemQuantity) * productAmount
    : 0;

export const portionsBasedOnProductOrderAmount = (
  product: { amount?: number | undefined | null, availableAsAlternativeUnitFor: string[] | null, avgWeight?: number | null | undefined },
  productPrice: { value: number } | null | undefined,
  orderAmount: number,
  clientGroupId?: string | null | undefined
) => {
  let totalPortions = 0;
  if (product.amount && productPrice?.value) {
    if (productIsAvailableAsAlternativeUnitFor(product, clientGroupId) && product.avgWeight) {
      totalPortions = productPrice.value * ((orderAmount) / product.amount);
    } else {
      totalPortions = productPrice.value * (orderAmount / product.amount);
    }
  }
  return roundHalf(totalPortions, 0.5);
};


export const priceInclBasedOnProductOrderAmount = (
  product: { amount?: number | undefined | null, availableAsAlternativeUnitFor: string[] | null, vat: Vat, avgWeight?: number | null | undefined },
  productPrice: { value: number } | null | undefined,
  orderAmount: number,
  clientGroupId?: string | null | undefined
) => {
  let totalPrice = 0;
  if (product.amount && productPrice?.value) {
    if (productIsAvailableAsAlternativeUnitFor(product, clientGroupId) && product.avgWeight) {
      totalPrice = productPrice.value * (orderAmount / product.amount);
    } else {
      totalPrice = productPrice.value * (orderAmount / product.amount);
    }
  }
  return totalPrice * (1+vat(product.vat));
};


export type BasketSize = 'mini' | 'large' | 'medium' | undefined;
export const getBasketSize = (product: { code?: string | null | undefined } | undefined | null): BasketSize =>
  product?.code && product.code.indexOf('large') > -1
    ? 'large'
    : product?.code && product.code.indexOf('medium') > -1
      ? 'medium'
      : product?.code && product.code.indexOf('mini') > -1
        ? 'mini'
        : undefined;


export const quantityFieldKeyForSize = (basketSize: BasketSize): 'quantityMini' | 'quantitySmall' | 'quantityLarge' | undefined =>
  basketSize === "large"
    ? 'quantityLarge'
    : basketSize === 'medium'
      ? 'quantitySmall'
      : basketSize === 'mini'
        ? 'quantityMini'
        : undefined;

export const portionsFieldKeyForSize = (basketSize: BasketSize): 'portionsMini' | 'portionsSmall' | 'portionsLarge' | undefined =>
  basketSize === "large"
    ? 'portionsLarge'
    : basketSize === 'medium'
      ? 'portionsSmall'
      : basketSize === 'mini'
      ? 'portionsMini'
      : undefined;

export const deliveryLocationsFieldKeyForSize = (basketSize: BasketSize): 'deliveryLocationsMini' | 'deliveryLocationsSmall' | 'deliveryLocationsLarge' | undefined =>
  basketSize === "large"
    ? 'deliveryLocationsLarge'
    : basketSize === 'medium'
      ? 'deliveryLocationsSmall'
      : basketSize === 'mini'
      ? 'deliveryLocationsMini'
      : undefined;


export const suggestedPointsFor = (price: number | undefined, productVat?: Vat) =>
  price ? roundHalf((price * (1 + vat(productVat)) / POINTS_PRICE_INCL), 0.5) : 0;

export const productIsAvailableAsAlternativeUnitFor = (product: { availableAsAlternativeUnitFor: string[] | null }, clientGroupId: string | undefined | null) => {
  return clientGroupId && compact(product.availableAsAlternativeUnitFor).includes(clientGroupId);

};

export const weightPerBunch = (product: {amount?: number | null | undefined, avgWeight?: number | null | undefined}) => {
  return product.avgWeight && product.amount ? product.avgWeight / product.amount : 0;
}
