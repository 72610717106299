import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import moment, {Moment} from "moment";
import {Calendar} from "primereact/calendar";
import SubscriptionProductOrderList from "../SubscriptionProductOrderList/SubscriptionProductOrderList";
import {chain, compact, first, last} from "lodash";
import {
  createBasketProductOrder,
  createBoxProductOrder,
  createExtraProductSubscriptions,
  createSelfHarvestProductOrder,
  findBasketProductOrder,
  findSelfHarvestProductOrder
} from 'shared/utils/productOrder.utils';
import {useMutation} from "@apollo/client";
import {
  filterProductOrdersByCategories,
  findProductOrderByCategory,
  findProductOrderByProductCode
} from "../../../../../../utils/productOrder.utils";
import {
  AmbassadorOption,
  ClientOrder_clientOrder,
  ClientOrder_clientOrder_productOrders,
  ClientOrder_clientOrder_productOrders_deliveries,
  ClientOrder_clientOrder_productOrders_deliveries_basketComposition,
  ClientOrderUpdate,
  ClientOrderUpdateInput,
  ClientOrderUpdateVariables,
  ProductOrderCreateWithoutClientOrderInput
} from "../../../../../../__generated__/types";
import {formulaBySubscriptionProductCode} from "../../../../../../shared/utils/formula.utils";
import {
  deliveryWeeksForSubscription,
  DeliveryWeeksForSubscription
} from "../../../../../../shared/utils/subscription.utils";
import {ClientOrderUpdateMutation} from "../../../../../../shared/queries/clientOrder.gql";
import {SubscriptionType} from "../../../../../../shared/types/types";
import {asIso8601, momentFromDdMmYyyy} from "../../../../../../shared/utils/date.utils";
import {toPickupDay} from "../../../../../../shared/utils/deliveryLocation.utils";

interface ChangeStartDateButtonProps {
  clientOrder: ClientOrder_clientOrder;
}

const ChangeStartDateButton = (props: ChangeStartDateButtonProps) => {
  const [clientOrderUpdate] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation);

  const [updatedStartDate, setUpdatedStartDate] = useState<Moment | undefined>();
  const [showCalendar, setShowCalendar] = useState<boolean>(true);
  const [updatedClientOrder, setUpdatedClientOrder] = useState<ClientOrder_clientOrder>(props.clientOrder);

  let basketProductOrder = findBasketProductOrder(props.clientOrder.productOrders);
  let selfHarvestProductOrder = findSelfHarvestProductOrder(props.clientOrder.productOrders);
  let boxesProductOrder = findProductOrderByProductCode(props.clientOrder.productOrders, 'pakket-bak');
  let ambassadorProductOrder = findProductOrderByCategory(props.clientOrder.productOrders, 'ambassadeur');
  let extraProductOrdersInClientOrder = filterProductOrdersByCategories(props.clientOrder.productOrders, ['uien', 'aardappelen', 'eieren', 'bloemen']);

  const subscriptionProductCode = basketProductOrder?.product ? basketProductOrder.product.code : selfHarvestProductOrder?.product?.code;
  const subscriptionProduct = basketProductOrder ? basketProductOrder.product : selfHarvestProductOrder?.product;

  useEffect(() => {
    let clientOrderUpdateInput = createUpdateData();

    let updatedProductOrders = chain(props.clientOrder.productOrders)
      .compact()
      .map((productOrder): ClientOrder_clientOrder_productOrders => {
        let deliveries: ClientOrder_clientOrder_productOrders_deliveries[] = chain(clientOrderUpdateInput?.productOrders?.update)
          .compact()
          .filter((productOrderUpdate) => {
            return productOrderUpdate.where.id === productOrder.id;
          })
          .map((productOrderUpdate): ClientOrder_clientOrder_productOrders_deliveries[] => {
            return compact(productOrderUpdate.data.deliveries?.create)
              .map((deliveryCreateInput): ClientOrder_clientOrder_productOrders_deliveries => {
                let delivery: ClientOrder_clientOrder_productOrders_deliveries = {
                  cancelled: !!deliveryCreateInput.cancelled,
                  deliveryLocation: deliveryCreateInput.deliveryLocation || null,
                  deliveryDate: deliveryCreateInput.deliveryDate,
                  deliveredProductIds: compact(deliveryCreateInput.deliveredProductIds?.set),
                  undeliverableProductIds: compact(deliveryCreateInput.undeliverableProductIds?.set),
                  plannedDeliveryDate: deliveryCreateInput.plannedDeliveryDate,
                  __typename: 'ProductOrderDelivery',
                  id: 'unknown',
                  basketComposition: null,
                  portions: null,
                };

                if (deliveryCreateInput.basketComposition?.connect?.id) {
                  delivery = {
                    ...delivery,
                    basketComposition: {
                      __typename: 'BasketComposition',
                      id: deliveryCreateInput.basketComposition.connect.id,
                    } as ClientOrder_clientOrder_productOrders_deliveries_basketComposition
                  };
                }
                return delivery
              })
          })
          .first()
          .value();

        return {
          ...productOrder,
          priceExcl: chain(clientOrderUpdateInput?.productOrders?.update)
            .compact()
            .find((productOrderUpdate) => {
              return productOrderUpdate.where.id === productOrder.id;
            })
            .value()?.data.priceExcl || productOrder.priceExcl,
          deliveries,
        };
      })
      .value();

    setUpdatedClientOrder(value => {
      if (updatedStartDate) {
        return {
          ...value,
          deliveryDate: clientOrderUpdateInput?.deliveryDate,
          deliveryEndDate: clientOrderUpdateInput?.deliveryEndDate,
          productOrders: updatedProductOrders,
        };
      }
      return value;
    });
  }, [updatedStartDate]);

  let onSave = () => {
    let clientOrderUpdateInput = createUpdateData();
    if (clientOrderUpdateInput) {
      const variables: ClientOrderUpdateVariables = {
        where: {id: props.clientOrder.id},
        data: clientOrderUpdateInput,
      };
      clientOrderUpdate({variables});
      setShowCalendar(false);
    }
  };

  const deliveryLocationForClientOrder = () => first(basketProductOrder?.deliveries)?.deliveryLocation;

  const deliveryLocationIsoWeekday = () => {
    const deliveryLocation = deliveryLocationForClientOrder();
    return deliveryLocation && toPickupDay(deliveryLocation) || 1;
  };

  const updatedProductOrderInputs = (deliveryWeeks: DeliveryWeeksForSubscription): ProductOrderCreateWithoutClientOrderInput[] => {
    const deliveryLocation = deliveryLocationForClientOrder();
    const isoWeekday = deliveryLocationIsoWeekday();

    if (!subscriptionProductCode) {
      return [];
    }
    let productOrders: (ProductOrderCreateWithoutClientOrderInput | undefined)[] = [];

    let formula = formulaBySubscriptionProductCode(subscriptionProductCode);
    if (formula?.formulaType === SubscriptionType.SELF_HARVEST) {
      let relatedProductOrder = findProductOrderByProductCode(props.clientOrder.productOrders, subscriptionProductCode);
      productOrders.push(createSelfHarvestProductOrder(deliveryWeeks, subscriptionProduct, {
          // BUG: should include members for correct price calculation
          members: [],
          subscriptionProductCode,
        },
        relatedProductOrder?.id));
    } else {
      let relatedProductOrder = findProductOrderByProductCode(props.clientOrder.productOrders, subscriptionProductCode);
      productOrders.push(createBasketProductOrder(deliveryWeeks,
        subscriptionProduct,
        {subscriptionProductCode},
        deliveryLocation,
        isoWeekday,
        relatedProductOrder?.id)
      );
      let boxProductOrder = findProductOrderByProductCode(props.clientOrder.productOrders, 'pakket-bak');
      productOrders = [...productOrders, createBoxProductOrder(boxesProductOrder?.quantity, boxProductOrder?.id)];
    }

    let ambassadorOption;
    if (ambassadorProductOrder) {
      let ambassadorProductCode = ambassadorProductOrder.product?.code;
      if (ambassadorProductCode === 'ambassadeur-50') {
        ambassadorOption = AmbassadorOption.AMBASSADOR_50;
      } else if (ambassadorProductCode === 'ambassadeur-100') {
        ambassadorOption = AmbassadorOption.AMBASSADOR_100;
      } else if (ambassadorProductCode === 'ambassadeur-200') {
        ambassadorOption = AmbassadorOption.AMBASSADOR_200;
      } else if (ambassadorProductCode === 'ambassadeur-275') {
        ambassadorOption = AmbassadorOption.AMBASSADOR_275;
      }

      //  productOrders.push(createAmbassadorProductOrder(ambassadorOption, ambassadorProductOrder, ambassadorProductOrder.id));
    }

    let extraProducts = chain(extraProductOrdersInClientOrder)
      .filter(po => !po.ambassadorDiscount)
      .map((productOrder) => {
        if(productOrder.product) {
          return ({
            categoryCode: productOrder.product.category?.code,
            productId: productOrder.product.id,
            frequencyCode: productOrder.frequency || undefined,
          });
        } return null;
      })
      .compact()
      .keyBy('categoryCode')
      .value();

    let extraProductOrders = createExtraProductSubscriptions(
      {
        subscriptionProductCode,
        extraProducts,
      },
      ambassadorOption,
      deliveryLocation,
      isoWeekday,
      updatedStartDate,
      extraProductOrdersInClientOrder,
    );
    return compact([...productOrders, ...extraProductOrders]);
  };

  const deliveryWeeksForUpdate = () => {
    // set isoweekday to 1 for calculation, this assumes that the first delivery will be in the week of the first expected delivery
    let dayBeforeFirstExpectedDelivery = updatedStartDate?.clone().isoWeekday(1);
    return deliveryWeeksForSubscription(subscriptionProductCode, dayBeforeFirstExpectedDelivery);
  };

  const createUpdateData = (): ClientOrderUpdateInput | undefined => {
    if (subscriptionProductCode) {
      const deliveryWeeks = deliveryWeeksForUpdate();
      let productOrders: ProductOrderCreateWithoutClientOrderInput[] = updatedProductOrderInputs(deliveryWeeks);
      return updateClientOrder(props.clientOrder, deliveryWeeks, productOrders);
    }
  };

  const updateClientOrder = (clientOrder: ClientOrder_clientOrder,
                             deliveryWeeks: DeliveryWeeksForSubscription,
                             productOrders: ProductOrderCreateWithoutClientOrderInput[] | undefined): ClientOrderUpdateInput => {
    let isoWeekday = deliveryLocationIsoWeekday();
    let deliveryDate = asIso8601(moment(`${first(deliveryWeeks.weeks)} ${isoWeekday}`, 'YYYY-W E'));
    let deliveryEndDate = asIso8601(moment(`${last(deliveryWeeks.possibleWeeks)} ${isoWeekday}`, 'YYYY-W E'));

    return {
      deliveryDate,
      deliveryEndDate,
      productOrders: {
        update: compact(productOrders).map(productOrder => ({
          where: {id: productOrder.id},
          data: {
            priceExcl: productOrder.priceExcl,
            deliveries: {
              deleteMany: [{
                id_in:
                  chain(clientOrder.productOrders)
                    .filter(po => po.id === productOrder.id)
                    .map('deliveries')
                    .flatten()
                    .map('id')
                    .value()
              }],
              ...productOrder.deliveries,
            }
          }
        }))
      },
    };
  };

  return <div className="border-1 border-300 surface-50 border-round p-2 px-4 my-2">
    <div className="flex align-items-center justify-content-between flex-wrap">
      <div className="text-xl font-medium">Wijzig de startdatum</div>
      <div className="flex align-items-center py-2">
        <div>Nieuwe startdatum:</div>
        <Calendar
          value={updatedStartDate ? updatedStartDate.toDate() : null}
          className="mx-2"
          inputClassName="p-dense"
          locale={'nl'}
          dateFormat={"dd-mm-yy"}
          showWeek
          onChange={(e) => {
            setUpdatedStartDate(momentFromDdMmYyyy(e.target.value));
          }}
        />
        <Button
          label={'Wijzig startdatum'}
          className="p-dense p-button-outlined"
          disabled={updatedStartDate === undefined}
          onClick={() => onSave()}
        />
      </div>
    </div>
    {
      showCalendar && updatedStartDate &&
      <div className="flex flex-column">
        <div className={"font-normal"}>Aangepast voorstel</div>
        {updatedClientOrder &&
          <SubscriptionProductOrderList clientOrder={updatedClientOrder}/>
        }
      </div>
    }
  </div>;
};

export default ChangeStartDateButton;
