import React from 'react';
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {BasketCompositions, BasketCompositionsVariables} from "../../../../__generated__/types";
import {portionAvgForBaskets} from "../../../../shared/utils/basketComposition.utils";
import {useQuery} from "@apollo/client";
import {BasketCompositionsQuery} from "../../../../shared/queries/basketComposition.gql";
import {compact} from "lodash";
import {useUserObject} from "../../../../shared/context/UserContext";

interface YearlyPointsOverviewProps {
  basketCompositionsSelector: Omit<BasketCompositionsVariables, "farmId">;
}

const YearlyPointsOverview = ({basketCompositionsSelector}: YearlyPointsOverviewProps) => {
  const {activeFarm} = useUserObject();

  let variables: BasketCompositionsVariables = {...basketCompositionsSelector, farmId: activeFarm?.id||''};
  const {data} = useQuery<BasketCompositions>(BasketCompositionsQuery, {
    variables: variables
  });

  let basketCompositions = compact(data?.basketCompositions);

  return <div className="flex justify-content-end">
    {[{year: [2025], onlyIsoweeksUptoThisWeek: true},
      {year: [2022, 2023,2024], onlyIsoweeksUptoThisWeek: false},
      {year: [2024], onlyIsoweeksUptoThisWeek: false},
      {year: [2023], onlyIsoweeksUptoThisWeek: false},
      {year: [2022], onlyIsoweeksUptoThisWeek: false}].map(year =>
      <div key={`yearSummary${year.year}${year.onlyIsoweeksUptoThisWeek ? 'Y' : 'N'}`}
           className="flex flex-column justify-content-center align-items-center mx-2">
        <div className="font-light text-2xl">{year.year}</div>
        <span className="text-2xs text-800">
            ({year.onlyIsoweeksUptoThisWeek
          ? 'Gemiddelde tot vandaag'
          : 'Jaargemiddelde'})
          </span>
        <div className="flex align-items-center justify-content-end border-bottom-1 border p-1 m-1 pr-4">
          <div className="flex align-items-center ml-3">
            <div className="flex flex-column align-items-center justify-content-center pr-2 w-3rem min-w-min">
              <div className="font-medium text-xl white-space-nowrap">
                {formatDoubleDigit(portionAvgForBaskets(basketCompositions, year.year, "mini", year.onlyIsoweeksUptoThisWeek))}
              </div>
              <div className="text-700 text-sm uppercase">Mini</div>
            </div>
            <div className="flex flex-column align-items-center justify-content-center pr-2 w-3rem min-w-min">
              <div className="font-medium text-xl white-space-nowrap">
                {formatDoubleDigit(portionAvgForBaskets(basketCompositions, year.year, "medium", year.onlyIsoweeksUptoThisWeek))}
              </div>
              <div className="text-700 text-sm uppercase">Klein</div>
            </div>
            <div className="flex flex-column align-items-center justify-content-center w-3rem  min-w-min">
              <div
                className="font-medium text-xl white-space-nowrap">
                {formatDoubleDigit(portionAvgForBaskets(basketCompositions, year.year, "large", year.onlyIsoweeksUptoThisWeek))}
              </div>
              <div className="text-700 text-sm uppercase">Groot</div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>;
};

export default YearlyPointsOverview;
