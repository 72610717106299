import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {GetProductsQuery} from "../../../../../shared/queries/product.gql";
import {
  BasketCompositionUpdate,
  BasketCompositionUpdateVariables,
  DeliveryLocation,
  GetProducts,
  GetProducts_products,
  GetProductsVariables,
  ProductOrdersForBasketComposition_productOrders
} from "../../../../../__generated__/types";
import {Column} from "primereact/column";
import {compact, groupBy, keys, map, orderBy, sum, uniq} from "lodash";
import {BasketItem} from './BasketCompositionViewer';
import {Button} from "primereact/button";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import {formatAsPoints, formatAsPointsShort, translatedUnit} from 'shared/utils/unit.utils';
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {DataTable} from "primereact/datatable";
import TotalColumn from "./BasketItemsTable/components/TotalColumn";
import {Moment} from "moment";
import {formatDoubleDigit, formatToMaxPrecision} from "../../../../../shared/utils/currency.utils";
import {BasketCompositionUpdateMutation} from "../../../../../shared/queries/basketComposition.gql";
import {deliveryLocations} from "../../../../../shared/utils/deliveryLocation.utils";
import DeliveryLocationCircle from "../DeliveryLocationCircle";
import {
  BasketSize,
  findProductPriceForClientGroupCode,
  portionsFieldKeyForSize,
  productIsAvailableAsAlternativeUnitFor,
  quantityFieldKeyForSize
} from "../../../../../shared/utils/product.utils";
import QuantityCell from "./BasketItemsTable/components/QuantityCell";
import {
  avgPointsSuggestion,
  portionDiffersFromCurrentlyCalculatedPortions,
  totalPortions
} from 'shared/utils/basketComposition.utils';
import {roundHalf} from "../../../../../shared/utils/number.utils";
import {
  useBasketCompositions_comparableForPreviousYear_Query
} from "../../../../../mutations/basketComposition.queries";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface BasketItemsTableProps {
  flexClientGroupCode: string;
  products: GetProducts_products[];
  deliveryWeek: Moment;
  basketCompositionId?: string;
  basketItems: BasketItem[];
  productOrders: ProductOrdersForBasketComposition_productOrders[];
  disabled?: boolean;
  onChange: (basketItem: {
    id: string | undefined,
    product: { id: string },

    quantityMini: number,
    portionsMini: number,
    deliveryLocationsMini: DeliveryLocation[];

    quantitySmall: number,
    portionsSmall: number,
    deliveryLocationsSmall: DeliveryLocation[];

    quantityLarge: number,
    portionsLarge: number,
    deliveryLocationsLarge: DeliveryLocation[];

    delete?: boolean
  }) => void;
}

export interface BasketItemRow {
  id: string;
  product?: GetProducts_products;

  quantityMini: number,
  portionsMini: number,
  deliveryLocationsMini: DeliveryLocation[];

  quantitySmall: number,
  portionsSmall: number,
  deliveryLocationsSmall: DeliveryLocation[];

  quantityLarge: number,
  portionsLarge: number,
  deliveryLocationsLarge: DeliveryLocation[];

  packagingOrder: number;
}


let basketItemHasDeliveryLocationsSet = (basketItemRow: {
  deliveryLocationsSmall?: string[],
  deliveryLocationsLarge?: string[]
}) =>
  compact(basketItemRow.deliveryLocationsSmall).length > 0 || compact(basketItemRow.deliveryLocationsLarge).length > 0;

const BasketItemsTable = (props: BasketItemsTableProps) => {
    const {activeFarm} = useUserObject();
    const {data: basketCompositionData} = useBasketCompositions_comparableForPreviousYear_Query(activeFarm?.id || '', props.deliveryWeek.startOf('isoWeek'));

    let variables: GetProductsVariables = {
      farmId: activeFarm?.id || '',
      clientGroupCode: props.flexClientGroupCode,
      where: {
        id_in: map(props.basketItems, 'product.id'),
      }
    };
    const {data} = useQuery<GetProducts>(GetProductsQuery, {
      variables: variables
    });

    const [basketItemRows, setBasketItemRows] = useState<BasketItemRow[]>([]);

    const [updateBasketComposition] = useMutation<BasketCompositionUpdate>(BasketCompositionUpdateMutation);

    useEffect(() => {
      const products = compact(data?.products);
      if (products) {
        setBasketItemRows(props.basketItems.map(basketItem => ({
          ...basketItem,
          product: products.find(product => product.id === basketItem.product.id)
        })));
      }
    }, [data, props.basketItems]);

    let portionsSuggestion: { [key in 'mini' | 'medium' | 'large']: number } = {mini: 0, medium: 0, large: 0};

    if (basketCompositionData?.basketCompositions) {
      console.log(map(compact(basketCompositionData.basketCompositions), 'id'));
      portionsSuggestion = ["medium", "large"].reduce((acc, basketSize: string) => {
        return {
          ...acc,
          [basketSize]: avgPointsSuggestion(compact(basketCompositionData.basketCompositions), basketSize as BasketSize, DeliveryLocation.WEELDE),
        };
      }, portionsSuggestion);
    }

    let totalPortionFooterCell = (basketSize: BasketSize) => () => {
      let portionsFieldKey = portionsFieldKeyForSize(basketSize);

      if (basketItemRows.findIndex(basketItemHasDeliveryLocationsSet) > -1) {

        let portionsWithDeliveryLocations = groupBy(deliveryLocations, deliveryLocation => formatDoubleDigit(totalPortions(basketItemRows, basketSize, deliveryLocation.value)));

        return <div>
          <div>Punten per dorp:</div>
          <div className="pt-1">
            {keys(portionsWithDeliveryLocations).map(totalPortions => {
                return <div className="flex align-items-center pb-1">
                  <div className="w-2rem text-right">{totalPortions}</div>
                  <div className="pl-1 flex justify-content-start">
                    {portionsWithDeliveryLocations[totalPortions].map(deliveryLocation =>
                      <div className="p-0"><DeliveryLocationCircle
                        deliveryLocation={deliveryLocation.value}/></div>
                    )}
                  </div>
                </div>;
              }
            )}
          </div>
          <div className="pt-2 flex align-items-center justify-content-start flex-wrap">
            {formatAsPoints(sum(basketItemRows.map(basketItemRow => portionsFieldKey ? basketItemRow[portionsFieldKey] : 0)))}
            <div className="text-color-secondary text-sm">
              Suggestie: {basketSize && formatToMaxPrecision(roundHalf(portionsSuggestion[basketSize], 0.25), 2)} p.
            </div>
          </div>
        </div>;
      } else {
        return <div className="pt-2 flex align-items-center justify-content-start flex-wrap">
          {formatAsPoints(sum(basketItemRows.map(basketItemRow => portionsFieldKey ? basketItemRow[portionsFieldKey] : 0)))}
          <div className="text-color-secondary text-sm">
            Suggestie: {basketSize && formatToMaxPrecision(roundHalf(portionsSuggestion[basketSize], 0.25), 2)} p.
          </div>
        </div>;
      }

    };
    let portionsCell = (basketSize: BasketSize) => (basketItemRow: BasketItemRow) => {
      let portionsFieldKey = portionsFieldKeyForSize(basketSize);
      let quantityFieldKey = quantityFieldKeyForSize(basketSize);

      if (portionsFieldKey && quantityFieldKey) {
        let portions = basketItemRow[portionsFieldKey];
        let quantity = basketItemRow[quantityFieldKey];
        if ((portions === undefined || portions === 0) && quantity > 0) {
          return <div className="color-orange font-bold"><i className="pi pi-exclamation-triangle pr-1"/>Geen
            porties
            gekend</div>;
        }

        const product = props.products.find(p => p.id === basketItemRow.product?.id);
        if (portionDiffersFromCurrentlyCalculatedPortions(props.flexClientGroupCode, basketItemRow, basketSize, product)) {

          return <div className="color-orange font-bold"><i className="pi pi-exclamation-triangle pr-1"/>afwijkend
          </div>;
        }


        let portionsFieldMini = portionsFieldKeyForSize("mini");
        let portionsFieldSmall = portionsFieldKeyForSize("medium");
        let portionsFieldLarge = portionsFieldKeyForSize("large");
        let portionsMini = portionsFieldMini ? basketItemRow[portionsFieldMini] : 0;
        let portionsSmall = portionsFieldSmall ? basketItemRow[portionsFieldSmall] : 0;
        let portionsLarge = portionsFieldLarge ? basketItemRow[portionsFieldLarge] : 0;

        if (uniq([portionsMini, portionsSmall, portionsLarge]).length === 1) {
          return <div>{formatAsPointsShort(portionsMini)}</div>
        }
        return <div>
          {portionsMini > 0 && <div>M: {formatAsPointsShort(portionsMini)}</div>}
          {portionsSmall > 0 && <div>K: {formatAsPointsShort(portionsSmall)}</div>}
          {portionsLarge > 0 && <div>G: {formatAsPointsShort(portionsLarge)}</div>}
        </div>;
      }
    }


    return <div>
      <DataTable
        className="sf-table"
        cellClassName={() => "vertical-align-top"}
        value={orderBy(basketItemRows, 'packagingOrder')}
        reorderableRows
        onRowReorder={(e) => {
          const variables: BasketCompositionUpdateVariables = {
            where: {id: props.basketCompositionId},
            data: {
              items: {
                update: e.value.map((item, index) => ({
                  where: {id: item.id},
                  data: {packagingOrder: index}
                }))
              }
            },
          };
          updateBasketComposition({variables});
        }}
        footerColumnGroup={
          <ColumnGroup>
            <Row>
              <Column footer={""} field="packagingOrder" colSpan={4}/>
              <Column footer={totalPortionFooterCell("mini")}/>
              <Column footer={totalPortionFooterCell("medium")}/>
              <Column footer={totalPortionFooterCell("large")}/>
              <Column colSpan={2}/>
            </Row>
          </ColumnGroup>
        }
        headerColumnGroup={
          <ColumnGroup>
            <Row>
              <Column header={""} field="packagingOrder" colSpan={2}/>
              <Column header={""}/>
              <Column header={"Product"}/>
              <Column header={"Punten mini"}/>
              <Column header={"Mini"}/>
              <Column header={"Klein"}/>
              <Column header={"Groot"}/>
              <Column header={"Totaal"}/>
              <Column header={""}/>
            </Row>
          </ColumnGroup>
        }
      >
        <Column
          rowReorder
          className="w-2rem"
          body={(basketItemRow: BasketItemRow) => basketItemRow.packagingOrder + 1}
        />
        <Column
          field="packagingOrder"
        />
        <Column
          //style={{minWidth: "50px", maxWidth: "50px", width: "50px", overflow: "hidden"}}
          field={'product.image'}
          body={(basketItemRow: BasketItemRow) => basketItemRow.product?.image &&
            <div className="flex align-items-center justify-content-center h-full">
              <img src={basketItemRow.product.image} className={'h-3rem max-h-3rem'}
                   alt={`afbeelding${basketItemRow.product.name}`}/>
            </div>
          }
        />
        <Column
          field={'product.name'}
          //style={{minWidth: '150px', verticalAlign: 'top'}}
          body={(basketItemRow: BasketItemRow) => {
            let product = basketItemRow.product;
            if (product) {
              const {amount, name, unit} = product;
              let productPrice = findProductPriceForClientGroupCode(product, props.flexClientGroupCode);
              const visibleAmount = amount && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, productPrice?.clientGroup?.id)
                ? 1
                : amount;

              const visibleUnit = productIsAvailableAsAlternativeUnitFor(product, productPrice?.clientGroup?.id)
                ? product.alternativeUnit
                : unit;

              const portions = productPrice?.value && amount && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, productPrice?.clientGroup?.id)
                ? productPrice.value / (amount / product.avgWeight)
                : productPrice?.value;

              return <div>
                <div className="font-bold">{name}</div>
                <div
                  className={"text-600 text-xs"}>{visibleAmount} {translatedUnit(visibleUnit)} = {portions} punten
                </div>
              </div>;
            }
          }}
        />

        <Column field={'portionsMini'} body={portionsCell("mini")}/>
        {/*<Column field={'portionsSmall'} body={portionsCell("medium")}/>*/}
        {/*<Column field={'portionsLarge'} body={portionsCell("large")}/>*/}

        <Column
          style={{minWidth: "200px", maxWidth: "200px", width: "200px", overflow: "hidden"}}
          field={'quantityMini'}
          body={(basketItemRow: BasketItemRow) => {
            return <QuantityCell size={'mini'} basketItemRow={basketItemRow} disabled={props.disabled}
                                 onChange={props.onChange} flexClientGroupCode={props.flexClientGroupCode}/>;
          }}
        />
        <Column
          style={{minWidth: "200px", maxWidth: "200px", width: "200px", overflow: "hidden"}}
          field={'quantitySmall'}
          body={(basketItemRow: BasketItemRow) => {
            return <QuantityCell size={'small'} basketItemRow={basketItemRow} disabled={props.disabled}
                                 onChange={props.onChange} flexClientGroupCode={props.flexClientGroupCode}/>;
          }}
        />
        <Column
          style={{minWidth: "200px", maxWidth: "200px", width: "200px", overflow: "hidden"}}
          field={'quantityLarge'}
          body={(basketItemRow: BasketItemRow) => {
            return <QuantityCell size={'large'} basketItemRow={basketItemRow} disabled={props.disabled}
                                 onChange={props.onChange} flexClientGroupCode={props.flexClientGroupCode}/>;
          }}
        />
        <Column
          //style={{minWidth: "200px", maxWidth: "200px", width: "200px", overflow: "hidden"}}
          body={(basketItemRow: BasketItemRow) => {
            if (basketItemRow.product?.amount) {
              return <TotalColumn product={basketItemRow.product} deliveryWeek={props.deliveryWeek}
                                  basketCompositionId={props.basketCompositionId}
                                  basketItemRow={basketItemRow}
                                  flexClientGroupCode={props.flexClientGroupCode}/>;
            }

          }}
        />
        <Column
          //style={{minWidth: "40px", maxWidth: "40px", width: "40px", overflow: "hidden"}}
          body={basketItemRow => {
            return <>
              <ConfirmPopup/>
              <Button
                disabled={props.disabled}
                icon={'pi pi-trash'}
                className={'p-button-text p-button-danger'}
                onClick={(e) => {
                  confirmPopup({
                    acceptLabel: 'Ja',
                    rejectLabel: 'Nee',
                    target: e.currentTarget,
                    message: 'Ben je zeker dat je dit product wil verwijderen?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      props.onChange({...basketItemRow, delete: true});
                    },
                    reject: () => {
                    }
                  });
                }}/></>
          }}
        />
      </DataTable>

    </div>;
  }
;

export default BasketItemsTable;
